<template lang="pug">
div.w-100
    .w-100
       ejs-richtexteditor(ref="rteObj"  v-model="companyForm.internalNote" :toolbarSettings="toolbarSettings" :height='"70%"')
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import {
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
  Table,
  FileManager,
  EmojiPicker,
} from "@syncfusion/ej2-vue-richtexteditor";

export default {
  props: {
    companyId: {
      default: null,
      required: true,
    },
    companyForm: {},
  },
  data() {
    return {
      showCharCount: true,
      quickToolbarSettings: {
        table: [
          "TableHeader",
          "TableRows",
          "TableColumns",
          "TableCell",
          "-",
          "BackgroundColor",
          "TableRemove",
          "TableCellVerticalAlign",
          "Styles",
        ],
      },
      toolbarSettings: {
        items: [
          "Bold",
          "Italic",
          "Underline",
          "StrikeThrough",
          "FontName",
          "FontSize",
          "FontColor",
          "BackgroundColor",
          "LowerCase",
          "UpperCase",
          "SuperScript",
          "SubScript",
          "EmojiPicker",
          "|",
          "Formats",
          "Alignments",
          "NumberFormatList",
          "BulletFormatList",
          "Outdent",
          "Indent",
          "|",
          "CreateTable",
          "CreateLink",
          "|",
          "ClearFormat",
          "Print",
          "SourceCode",
          "FullScreen",
          "|",
          "Undo",
          "Redo",
        ],
      },
    };
  },
  async created() {},
  methods: {},
  provide: {
    richtexteditor: [
      Toolbar,
      Link,
      Image,
      Count,
      HtmlEditor,
      QuickToolbar,
      Table,
      FileManager,
      EmojiPicker,
    ],
  },
};
</script>
<style>
@import "~@syncfusion/ej2-vue-richtexteditor/styles/bootstrap5.css";
</style>
