export function isMobile() {
  return window.innerWidth <= 767;
}

export async function checkImageExists(imageUrl) {
  try {
    const response = await fetch(imageUrl, { method: "HEAD" });
    return response.ok;
  } catch (error) {
    return false;
  }
}
