<template lang="pug"> 
div
    b-modal#modal-prevent-closing-import-credits-provider(ok-title="Nouvel inventaire" hide-footer cancel-title="Annuler" size="xl" ref='modal-import-credits-provider' title='Importer des avoirs fournisseur')
        template.d-block(#modal-header="{ close }")
            div.w-100
                button.absolute(type="button" aria-label="Close" class="close" @click="close()") ×
                b-row.my-0
                    b-col.py-0.mb-0(cols='12' style="padding:0px !important")
                        h3.text-primary Importer des avoirs fournisseur
        .justify-content-center(v-if="isCreatingCreditProvider")
            .content-loader-center.m-0(style="min-height:250px")
                .text-center.flex-center
                    .loading-bg-inner(variant="primary" style="width: 3rem; height: 3rem;")
                        .loader
                            .outer
                            .middle   
                            .inner
                    span(style="margin-top: 8rem")  Import des avoirs fournisseur en cours ...
        .justify-content-center.py-50.h-100(v-else-if="selectInstitution != null")
            .d-flex.w-100.h-100(style='flex: 1;')
                .inputDropZoneSidebar
                ejs-uploader(ref="uploadObj" :maxFileSize="10000000" @success="success" @selected="selected" allowedExtensions=".pdf,.jpeg,.jpg,.png" @uploading="uploading" :asyncSettings="asyncSettings" name="file")
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import {
  startDate,
  endDate,
} from "@/store/modules/purchase/creditProvider/actions.ts";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    selectInstitution: null,
    isMultiple: false,
  }),
  computed: {
    ...mapGetters([
      "institutionSelected",
      "isCreatingCreditProvider",
      "institutionsList",
      "userParametersList",
    ]),
    asyncSettings() {
      return {
        saveUrl: `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/UploadCreditProviderFile`,
      };
    },
  },
  methods: {
    ...mapActions(["getCreditsProviders", "setFilter"]),
    formatDate,
    success(res) {
      document.getElementsByClassName("e-file-delete-btn").forEach((el) => {
        el.style.display = "none";
      });
      let allUploaded = true;
      this.$refs.uploadObj.getFilesData().forEach((el) => {
        if (el.statusCode != 2) {
          allUploaded = false;
        }
      });
      //Si tout est upload
      if (allUploaded) {
        this.$refs.uploadObj.clearAll();
        this.getCreditProvidersLocal();
        this.$refs["modal-import-credits-provider"].hide();
        if (!this.isMultiple) {
          //open tab of the created credit
          this.$router.push({
            name: "edit-credit-provider",
            params: { id: res.e.target.response },
          });
        }
      }
    },
    selected(data) {
      this.isMultiple = data.filesData.length > 1;
    },
    toggleModal() {
      this.selectInstitution = this.institutionSelected;
      this.$refs["modal-import-credits-provider"].toggle(
        "#toggle-btn-import-credits-provider"
      );
    },
    uploading(args) {
      args.currentRequest.open("PUT", this.asyncSettings.saveUrl, true);
      args.currentRequest.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("userToken")}`
      );
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        await this.getCreditsProviders({
          startTo: dateRange.startDate,
          endTo: dateRange.endDate,
        });
      }
    },
    getCreditProvidersLocal() {
      let creditProviderFilters = this.userParametersList.find(
        (el) => el.key == "creditProviderFilters"
      );
      if (creditProviderFilters) {
        this.setDates(JSON.parse(creditProviderFilters.value).dateRange);
      } else {
        this.setFilter({
          name: "creditProviderFilters",
          filter: {
            dateRangeType: 7,
            dateRange: { startDate: startDate, endDate: endDate },
          },
        });
        this.getCreditsProviders({
          startTo: startDate,
          endTo: endDate,
        });
      }
    },
  },
};
</script>

<style>
#modal-prevent-closing-import-credits-provider .modal-header {
  padding-bottom: 0 !important;
}
#modal-prevent-closing-import-credits-provider .modal-content {
  height: calc(100dvh - 3.5rem);
}
</style>
