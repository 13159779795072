<template lang="pug">
  .h-100.d-flex.flex-column
    HeaderGrid(:name="name" :title='title' :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch" :archiveMode="archiveMode" :archive="archive")
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingProductProvidersList || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des produits fournisseur...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebarProductProvider" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-product-provider-content(ref='sidebarProductProviderContent' @closeSidebar="closeSidebar" @refreshGrid="refreshGrid")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

import SidebarProductProviderContent from "@/components/purchase/product-provider/SidebarProductProviderContent.vue";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarProductProviderContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      title: "Produits fournisseur",
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "productProvider",
      uid: "companyProductProviders",
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      documentSelected: {},

      tvaMode: "HT",
      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: true,
      searchPlaceholder: "Rechercher un produit fournisseur",
      archive: false,
      archiveMode: true,
    };
  },
  computed: {
    ...mapGetters([
      "productProvidersList",
      "isLoadingProductProvidersList",
      "company",
    ]),
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.$store.getters[this.name + "sList"].filter((elem) =>
          elem[this.name + "Tags"].find((tag) =>
            this.tagsSelected.includes(tag.id)
          )
        );
      } else {
        return this.$store.getters[this.name + "sList"];
      }
    },
    dataFiltered() {
      if (!this.archive) {
        return this.$store.getters[this.name + "sList"];
      } else {
        return this.$store.getters[this.name + "sListArchived"];
      }
      // if (this.statusList) {
      //   if (this.tabActive == "all") {
      //     if (this.tagsSelected.length) {
      //       return this.$store.getters[this.name + "sList"].filter(
      //         (elem) =>
      //           elem[this.name + "sList"].find((tag) =>
      //             this.tagsSelected.includes(tag.id)
      //           )
      //       );
      //     } else {
      //       return this.$store.getters[this.name + "sList"];
      //     }
      //   } else {
      //     let statusInfos = this.statusList.find(
      //       (elem) => elem.value == this.tabActive
      //     );
      //     if (this.tagsSelected.length) {
      //       return this.$store.getters[this.name + "sList"].filter(
      //         (elem) =>
      //           elem[this.name + "Tags"].find((tag) =>
      //             this.tagsSelected.includes(tag.id)
      //           ) &&
      //           (statusInfos.query
      //             ? statusInfos.query.includes(elem.status)
      //             : elem.status === this.tabActive)
      //       );
      //     } else {
      //       return this.$store.getters[this.name + "sList"].filter(
      //         (elem) =>
      //           statusInfos.query
      //             ? statusInfos.query.includes(elem.status)
      //             : elem.status === this.tabActive
      //       );
      //     }
      //   }
      // } else {
      //   return [];
      // }
    },
  },
  props: {
    companyId: {
      default: null,
      required: true,
    },
  },
  async created() {
    await this.getProductProviders({
      companyId: this.companyId,
    }).then(async (res) => {
      this.setHeaderData();
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
    await this.getCompanies({});
    await this.getCollaborators({});
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getCollaborators",
      "getProductProviders",
      "getProductProvidersArchived",
    ]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    setDates(e) {},
    async setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: true,
        },
        {
          type: "string",
          field: "productType",
          filterTemplate: "productTypeFilterTemplate",
          headerText: "Type",
          width: 140,
          maxWidth: 140,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          template: "productTypeTemplate",
          visible: true,
        },
        {
          type: "string",
          field: "reference",
          headerText: "Référence",
          width: 140,
          minWidth: 140,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: false,
          visible: true,
          allowGrouping: false,
        },
        // {
        //   type: "string",
        //   field: "company.name",
        //   headerText: "Fournisseur",
        //   width: 140,
        //   minWidth: 140,
        //   allowFiltering: true,
        //   allowSorting: true,
        //   showInColumnChooser: false,
        //   visible: true,
        // },
        {
          type: null,
          field: "description",
          headerText: "Désignation",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowGrouping: false,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          filter: { type: "Menu", operator: "contains" },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "quantity",
          headerText: "Qté",
          width: 100,
          minWidth: 100,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "unit.label",
          headerText: "Unité",
          width: 150,
          minWidth: 150,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "number",
          field: "netPrice",
          headerText: "Prix d'achat HT",
          width: 150,
          minWidth: 150,
          textAlign: "right",
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          format: "formatCurrency",
          allowGrouping: false,
          allowFiltering: true,
        },
      ];
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({
        name: "new-product-provider",
        params: {
          companyId: this.companyId,
        },
      });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getProductProvidersArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getProductProviders({ companyId: this.companyId });
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              sum += obj["totalTtc"];
            } else {
              sum += obj["totalHt"];
            }
          });
      }
      return this.formatCurrency(sum);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarProductProviderContent"].getProductProviderDetails(
        event.rowData.id,
        event.rowData.nature
      );
      this.$refs.sidebarProductProvider.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    closeSidebar() {
      this.$refs.sidebarProductProvider.hide();
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
  },
};
</script>
