<template lang="pug">
div.h-100
  .h-100(v-if='isLoadingDeliveryForm || isUpdatingDeliveryForm || isCreatingDeliveryForm')
    .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      div(style="margin-top: 7rem;")
        | {{ loadingMsg }}
  .h-100(v-else='')
    .sidebar-header.pt-1.px-2
      div
        h3 {{ 'Livraison : ' + (deliveryForm.documentReference ? deliveryForm.documentReference : 'en attente') }}
      feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
    .sidebar-header.pb-1.px-2
      .d-flex.align-items-center
        Status(:statusId="deliveryForm.status" nature="deliveryForm")
        span {{ deliveryForm.documentDate ? "En date du : " + dayjs(deliveryForm.documentDate).format("DD/MM/YYYY") : "" }}
      .end
    vs-divider.m-0
    .sidebar-action.py-05.px-2
      .d-flex.align-items-center.justify-content-between.w-100
        .d-flex
          b-button-group
            b-button.btn-icon.d-flex(variant='primary' @click="editDocument(deliveryForm.id, 'delivery-form-pdf')" :style="'border-right:1px solid #ccc !important;'")
              feather-icon(icon='EyeIcon')
            b-button.btn-icon.d-flex(v-if='deliveryForm.status < 3' style='border-left:1px solid #ccc !important;' variant='primary' @click="editDocument(deliveryForm.id, 'delivery-form-edit')")
              feather-icon(icon='EditIcon')
              span(style='margin-left:3px') Modifier
            b-button.btn-icon.d-flex(v-if='deliveryForm.status >= 3 && deliveryForm.path' @click.stop='forceFileDownload(deliveryForm.path)' style='border-left:1px solid #ccc !important;' variant='primary')
              feather-icon.align-middle(icon='DownloadIcon')
              span(style='margin-left:3px') Télécharger
        .d-flex.align-items-center.justify-end
          b-button-group
            b-button( variant="outline-danger" class="btn-icon" v-if="deliveryForm.status == 0 || deliveryForm.status == 1" @click="deleteDocumentLocal(deliveryForm.id, deliveryForm.documentReference)")
              feather-icon.mr-50(icon="TrashIcon") 
              | Supprimer définitivement
            b-dropdown.dropdown-icon-wrapper(variant='outline-primary' left='')
              template(#button-content='')
                feather-icon.align-middle(icon='MoreHorizontalIcon')
              b-dropdown-item(@click.stop='duplicateDocument') Dupliquer
              b-dropdown-divider
              b-dropdown-item(@click.stop='cancelDocument' v-if="deliveryForm.status > 2 && deliveryForm.status !== 4") Annuler
              b-dropdown-item(@click.stop='deleteDocumentLocal(deliveryForm.id, deliveryForm.documentReference)' v-if="deliveryForm.status < 3") Supprimer
    vs-divider.m-0
    b-row.content-scrollable-sticky.m-0.justify-content-center
        b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
          ul.d-flex.border-bottom-grey-light.px-1
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                span Détails
            li(v-if="haveGedModule")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'files' ? 'current' : ''" @click="tabActive = 'files'")
                span Documents
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notes' ? 'current' : ''" @click="tabActive = 'notes'")
                span Notes
                feather-icon.align-middle.ml-50(v-if="deliveryForm.internalNote" icon='MessageCircleIcon')
    .sidebar-content.with-tabs
      div(v-if='tabActive == "details"')
        div
          .d-flex.justify-content-between
            h5.mb-0.d-flex.align-items-center.mb-0.text-primary
              span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                feather-icon(icon='ChevronRightIcon') 
                | Détails
            .d-flex
          ul.listTab.mt-1
            li(v-if='deliveryForm.collaboratorId')
              span Remis à
              span {{ deliveryForm.collaboratorFirstName + " " + deliveryForm.collaboratorLastName }}
            li(v-if='deliveryForm.deliveredQuantity>0')
              span Quantité
              span {{ deliveryForm.deliveredQuantity>1 ? deliveryForm.deliveredQuantity + ' articles' : deliveryForm.deliveredQuantity + ' article' }}
            li(v-if='deliveryForm.valueHt>0')
              span Valeur
              span {{ formatCurrency(deliveryForm.valueHt.toFixed(2)) }}
          vs-divider
        div(v-if='deliveryForm.companyCustomerId')
          .d-flex.justify-content-between
            h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editCompany(deliveryForm.companyCustomerName, 'client')" @mouseover="showCompanyCustomerEdit=true" @mouseleave="showCompanyCustomerEdit=false" style="flex: 1;")
              span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                feather-icon(icon='ChevronRightIcon') 
                | {{ deliveryForm.companyCustomerName }}
                feather-icon.ml-50(v-show="showCompanyCustomerEdit" icon='ExternalLinkIcon')
            .d-flex
              div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
              div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                div.px-50
                  small Client
          div
            span(v-show='deliveryForm.companyCustomerAddress') {{ deliveryForm.companyCustomerAddress }}
              br
            span(v-show='deliveryForm.companyCustomerAddressComplement') {{ deliveryForm.companyCustomerAddressComplement }}
              br
            span(v-show='deliveryForm.companyCustomerZipCode || deliveryForm.companyCustomerCity') {{ deliveryForm.companyCustomerZipCode }} {{ deliveryForm.companyCustomerCity }}
              br
            span(v-show='deliveryForm.companyCustomerCountry') {{ deliveryForm.companyCustomerCountry }}
          ul.listTab.mt-1
            li(v-if='deliveryForm.companyCustomerEmail')
              span Email
              span {{ deliveryForm.companyCustomerEmail }}
            li(v-if='deliveryForm.companyCustomerPhoneNumber')
              span Téléphone
              span {{ deliveryForm.companyCustomerPhoneNumber }}
          vs-divider
        div(v-if='deliveryForm.affairId')
          .d-flex.justify-content-between.mb-1
              h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(@click="editAffair(deliveryForm.affairId)" @mouseover="showAffairEdit=true" @mouseleave="showAffairEdit=false" style="flex: 1;")
                span(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ deliveryForm.affairName }}
                  feather-icon.ml-50(v-show="showAffairEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Affaire liée
        vs-divider(v-if='deliveryForm.affairId')
        div(v-if='deliveryForm.storageLocationId || deliveryForm.deliverToAffairAddress')
          .d-flex.justify-content-between
              h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editStorageLocation(deliveryForm.storageLocationId)" @mouseover="showStorageLocationEdit=true" @mouseleave="showStorageLocationEdit=false" style="flex: 1;")
                .d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ deliveryForm.storageLocationLabel }}
                  feather-icon.ml-50(v-show="showStorageLocationEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Destockage
          div
            span(v-show='deliveryForm.storageLocationAddress') {{ deliveryForm.storageLocationAddress }}
              br
            span(v-show='deliveryForm.storageLocationAddressComplement') {{ deliveryForm.storageLocationAddressComplement }}
              br
            span(v-show='deliveryForm.storageLocationZipCode || deliveryForm.storageLocationCity') {{ deliveryForm.storageLocationZipCode }} {{ deliveryForm.storageLocationCity }}
              br
            span(v-show='deliveryForm.storageLocationCountry') {{ deliveryForm.storageLocationCountry }}
          ul.listTab.mt-1(v-if='deliveryForm.storageLocationId')
            li(v-if='deliveryForm.storageLocationEmail')
              span Email
              span {{ deliveryForm.storageLocationEmail }}
            li(v-if='deliveryForm.storageLocationPhoneNumber')
              span Téléphone
              span {{ deliveryForm.storageLocationPhoneNumber }}
            li(v-if='deliveryForm.deliverToAffairAddress')
              span Adresse
              span Livré sur le lieu de l'affaire
            li(v-if='deliveryForm.receiptDate')
              span Réceptionné le
              span {{ dayjs(deliveryForm.receiptDate).format("DD/MM/YYYY")  }}
          vs-divider 
        div
          HistoryDeliveryForm          
      div.h-100(v-if='tabActive == "files"')
        ged-viewer-sidebar(:parentFolder="deliveryForm.folderId" :fromModule="'deliveryForm'")
      div.h-100(v-if='tabActive == "notes"')
        quill-editor(id="text-area-designation" v-model="deliveryForm.internalNote" toolbar="essential" @blur="updateDeliveryFormWithoutLoading" class="editor quill-fixed" :options="editorOption" style="height: calc(100% - 55px);")
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import axios from "axios";

import {
  formatCurrency,
  formatNumber,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import Status from "@/components/global/status/Status";
import HistoryDeliveryForm from "@/components/purchase/delivery-form/HistoryDeliveryForm";
import GedViewerSidebar from "@/components/ged/GedViewerSidebar";
import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  data() {
    return {
      tabActive: "details",
      dayjs: dayjs,
      loadingTitle: "Chargement",
      loadingMessage: "Chargement des détails du bon de sortie...",
      showStorageLocationEdit: false,
      showCompanyCustomerEdit: false,
      showAffairEdit: false,
      editorOption: {
        placeholder: "Ecrivez une note interne liée à ce document ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        },
      },
      dataOrigine: {},
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingDeliveryForm",
      "isCreatingDeliveryForm",
      "isLoadingDeliveryForm",
      "isDeletingDeliveryForm",
      "isLoadingDeliveryFormPDF",
      "workspaceSelected",
      "isLoadingDuplicatingDocument",
    ]),

    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
    deliveryFormDetails: {
      get() {
        return this.$store.getters["getDeliveryFormDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM_DETAILS", value);
      },
    },
    isLoadingDuplicatingDocument: {
      get() {
        return this.$store.getters["isLoadingDuplicatingDeliveryForm"];
      },
      set(value) {
        return this.$store.commit(
          "SET_IS_LOADING_DUPLICATING_DELIVERY_FORM",
          value
        );
      },
    },
    loadingMsg: {
      get() {
        return this.$store.getters["loadingMsg"];
      },
      set(value) {
        return this.$store.commit("SET_LOADING_MSG", value);
      },
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
  },
  methods: {
    ...mapActions([
      "getDeliveryFormById",
      "updateDeliveryForm",
      "deleteDeliveryForm",
      "duplicateDeliveryForm",
      "getDeliveryFormFromDeliveryFormId",
    ]),
    checkFunctionUser,
    formatCurrency,
    formatNumber,
    formatDate,
    async updateDeliveryFormWithoutLoading() {
      if (this.dataOrigine.internalNote !== this.deliveryForm.internalNote) {
        await this.updateDeliveryForm({
          deliveryForm: this.deliveryForm,
          loading: false,
        });
      }
    },
    async newDeliveryForm() {
      //create empty order form
      await this.getDeliveryFormFromDeliveryFormId(this.deliveryForm.id).then(
        (deliveryForm) => {
          this.$router.push({
            name: "new-delivery-form",
            params: {
              id: deliveryForm.id,
              title: "Nouveau bon de sortie",
              tips: "Nouveau bon de sortie",
              routeOrigine: "delivery-forms",
            },
          });
          this.closeSidebar();
        }
      );
    },
    async editDocument(id, mode) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-delivery-form",
        params: {
          mode: mode,
          id: id,
          title: this.deliveryForm.documentReference
            ? this.deliveryForm.documentReference
            : "Editer un bon de sortie",
          tips:
            "Editer le bon de sortie" + this.deliveryForm.documentReference
              ? " : " + this.deliveryForm.documentReference
              : "",
          routeOrigine: "delivery-forms",
        },
      });
    },
    async showAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "affair",
        params: {
          id: id,
          title: "Affaire : " + this.affair.name,
          tips: "Affaire : " + this.affair.name,
          routeOrigine: "affairs",
        },
      });
    },
    async editCustomer(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title: "Edit : " + this.deliveryForm.companyCustomerName,
          tips:
            "Editer l'entreprise : " + this.deliveryForm.companyCustomerName,
          routeOrigine: "delivery-forms",
        },
      });
    },
    async editCollaborator(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-collaborator",
        params: {
          id: id,
          title:
            "Edit : " +
            this.deliveryForm.collaboratorFirstName +
            " " +
            this.deliveryForm.collaboratorLastName,
          tips:
            "Editer le collaborateur : " +
            this.deliveryForm.collaboratorFirstName +
            " " +
            this.deliveryForm.collaboratorLastName,
          routeOrigine: "delivery-forms",
        },
      });
    },
    async editAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-affair",
        params: {
          id: id,
          title: "Edit : " + this.deliveryForm.affairName,
          tips: "Editer l'affaire : " + this.deliveryForm.affairName,
          routeOrigine: "delivery-forms",
        },
      });
    },
    async editCompany(id, type) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title:
            "Edit : " + type == "client"
              ? this.deliveryForm.companyCustomerName
              : this.deliveryForm.companyCustomerName,
          tips:
            "Editer l'entreprise : " + type == "client"
              ? this.deliveryForm.companyCustomerName
              : this.deliveryForm.companyProviderName,
          routeOrigine: "companies",
        },
      });
    },
    async getDocumentDetails(id) {
      if (id != "") {
        this.getDeliveryFormById({ deliveryFormId: id }).then((res) => {
          this.dataOrigine = JSON.parse(JSON.stringify(res));
        });
        this.tabActive = "details";
      }
    },
    duplicateDocument() {
      this.loadingMsg = "Duplication en cours...";
      this.isLoadingDuplicatingDocument = true;
      this.duplicateDeliveryForm(this.deliveryForm.id).then(async (res) => {
        this.closeSidebar();
        this.isLoadingDuplicatingDocument = false;
        this.loadingMsg = "Chargement en cours...";
        this.$router.push({
          name: "new-delivery-form",
          params: {
            id: res.data.id,
            title: "Nouveau bon de sortie",
            tips: "Nouveau bon de sortie",
            routeOrigine: "delivery-forms",
          },
        });
      });
    },
    async cancelDocument() {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir supprimer ce bon de sorti ?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.updateDeliveryForm({
              deliveryForm: { ...this.deliveryForm, status: 4, path: "" },
              loading: false,
            }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    deleteDocumentLocal(id, reference) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            'Êtes-vous sûr de vouloir supprimer le bon de sortie "' +
            reference +
            '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteDeliveryForm({ deliveryFormIds: [id] }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute("download", this.deliveryForm.documentReference);
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  mounted() {
    this.loadingTitle = "Chargement";
    this.loadingMessage = "Chargement des détails du bon de sortie...";
  },
  components: {
    VuePerfectScrollbar,
    quillEditor,
    HistoryDeliveryForm,
    Status,
    GedViewerSidebar,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}

.cancel-modal-buttons {
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;

    .dropdown-content-item {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
