<template lang="pug"> 
    div
      b-modal#modal-prevent-closing-new-receipt-form-provider(ok-title="Nouveau bon de réception" hide-footer cancel-title="Annuler" size="lg" ref='modal-new-receipt-form-provider' title='Nouveau devis' @ok='handleOk' :header-class="'bg-light-primary'" :ok-disabled="!institutionSettingsActive")
        template.d-block(#modal-header="{ close }")
          div.w-100
            button.absolute(type="button" aria-label="Close" class="close" @click="close()") ×
            b-row.my-0
              b-col.py-0.mb-0(cols='12' style="padding:0px !important")
                h3.text-primary Nouveau bon de réception
            b-row.content-scrollable-sticky.m-0.justify-content-center
              b-col.content-scrollable-sticky-inner#nav-sticky-horizontal(cols="12" style="padding: 0 !important; height: 100% !important")
                ul.d-flex
                  li
                    a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'blank' ? 'current' : ''" @click="tabActive = 'blank'")
                      span Nouveau
                  li
                    a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'fromOrderFormProvider' ? 'current' : ''" @click="tabActive = 'fromOrderFormProvider'")
                      span À partir d'une commande fournisseur
        .list-buttom-custom.m-1(v-if="tabActive == 'blank'")
          div.relative.doc-buttom-custom(@click="handleSubmit()")
            img.w-100(:src="getSrcIcon()")
        #receipt-form-provider-by-order-form-provider(v-else)
          b-row
            selectOrderFormProvider(:options="orderFormProvidersList.filter(elem => elem.status == 3 || elem.status == 5)" @setValue="setOrderFormProvider" placeholder="Sélectionner une commande fournisseur")
          div(v-if="selectedOrderFormProvider != null")

          b-row.my-0(v-if="selectedOrderFormProvider != null")
            b-col.py-0(cols='12')
              .d-flex.justify-content-between(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem;')
                div
                  b-button(variant='outline-primary' @click='toggleModal')
                    feather-icon(icon='ArrowLeftIcon')
                      | Annuler
                b-button(variant='primary' @click='newReceiptFormProvider')
                  | Créer le bon de réception
  
  </template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import selectOrderFormProvider from "@/components/purchase/receipt-form-provider/builder/SelectOrderFormProvider.vue";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    selectOrderFormProvider,
    BModal,
    "v-select": vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    submittedNames: [],
    selectedOrderFormProvider: null,
    tabActive: "blank",
  }),
  props: {
    affairId: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "institutionSelected",
      "institutionSettingsActive",
      "isLoadingInstitutionsList",
      "institutionsList",
      "workspaceSelected",
      "orderFormProvidersList",
    ]),
    document: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
  },
  created() {
    this.getOrderForms({ dateFrom: null, dateTo: null });
  },
  methods: {
    ...mapActions([
      "getReceiptFormEmpty",
      "getOrderForms",
      "getReceiptFormOrderFormId",
    ]),
    setOrderFormProvider(value) {
      this.selectedOrderFormProvider = value;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async newReceiptFormProvider() {
      //create empty order form
      await this.getReceiptFormOrderFormId({
        orderFormProviderId: this.selectedOrderFormProvider.id,
      }).then((receiptFormProvider) => {
        this.$router.push({
          name: "new-receipt-form-provider",
          params: {
            id: receiptFormProvider.id,
            title: "Nouveau bon de réception",
            tips: "Nouveau bon de réception",
            routeOrigine: "receipt-form-providers",
          },
        });
        this.$refs["modal-new-receipt-form-provider"].toggle(
          "#toggle-btn-new-receipt-form-provider"
        );
        this.tabActive = "blank";
      });
    },
    getSrcIcon() {
      return require("@/assets/icones/icon-doc-standard.svg");
    },
    async handleSubmit(quoteType = null) {
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création du bon de réception en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      await this.getReceiptFormEmpty({
        customerId: this.company.companyType == 8 ? null : this.companyId,
        providerId: this.company.companyType == 8 ? this.companyId : null,
        affairId: this.affairId,
      })
        .then((quote) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          this.$router.push({
            name: "new-receipt-form-provider",
            params: {
              id: quote.id,
              title: "Nouveau bon de réception",
              tips: "Nouveau bon de réception",
              routeOrigine: "receipt-form-providers",
            },
          });
          this.$refs["modal-new-receipt-form-provider"].toggle(
            "#toggle-btn-new-receipt-form-provider"
          );
          this.tabActive = "blank";
        })
        .catch((error) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        });
    },
    toggleModal() {
      this.$refs["modal-new-receipt-form-provider"].toggle(
        "#toggle-btn-new-receipt-form-provider"
      );
      this.tabActive = "blank";
    },
  },
};
</script>

<style>
#modal-prevent-closing-new-receipt-form-provider .modal-header {
  padding-bottom: 0 !important;
}
</style>
