<template>
  <div>
    <h4 class="mb-2 text-primary d-flex justify-content-between">
      <span> <feather-icon icon="ChevronRightIcon" /> Historique </span>
    </h4>
    <app-timeline v-if="deliveryFormHistoriesList.length">
      <app-timeline-item
        v-for="(item, index) in deliveryFormHistoriesList"
        :key="index"
        :title="item.comments"
        :time="formatDate(item.date)"
        variant="secondary"
      />
    </app-timeline>
    <span v-else>Aucun historique</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import dayjs from "dayjs";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  computed: {
    ...mapGetters(["deliveryFormHistoriesList"]),
  },
  methods: {
    formatDate,
  },
  components: {
    AppTimeline,
    AppTimelineItem,
  },
};
</script>
