<template lang="pug">
  .h-100
    .h-100(v-if='isLoadingProductProvider || isCreatingProductProvider || isDeletingProductProvider')
      .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        div(style='margin-top: 7rem;')
          | Chargement des d&eacute;tails du produit fournisseur...
    .h-100()
      .sidebar-header.py-1.px-2
        div
          h3 {{ productTypeTranslate(productProvider.productType) }}
        feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
      vs-divider.m-0
      .sidebar-action.py-05.px-2
        .d-flex.align-items-center
          b-button.btn-icon(v-if='archived' variant='warning' @click='restoreProductProviderLocal(productProvider.id)')
            feather-icon(icon='ArrowDownIcon')
              | Restaurer
          b-button.btn-icon(variant='primary' @click='editProductProvider(productProvider.id)' :disabled='archived')
            feather-icon(icon='EditIcon')
              | Modifier
        b-button-group
          //- b-button.btn-icon(v-if='archived' variant='outline-danger' @click='deleteProductProviderLocal(productProvider)')
          //-   feather-icon(icon='TrashIcon')
          //-     | Supprimer d&eacute;finitivement
          b-button.btn-icon(variant='outline-danger' @click='archiveProductProviderLocal(productProvider.id)')
            feather-icon(icon='ArchiveIcon')
      vs-divider.m-0
      b-row.content-scrollable-sticky.m-0.justify-content-center
        b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
          ul.d-flex.border-bottom-grey-light.px-1
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'purchases' ? 'current' : ''" @click="tabActive = 'purchases'")
                span Achat
            li(v-if="product")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'sales' ? 'current' : ''" @click="tabActive = 'sales'")
                span Vente
      .sidebar-content
        div(v-if="tabActive == 'purchases'")
          div(v-if='productProvider.company')
            h4.mb-1.text-primary.d-flex.justify-content-between
              span
                feather-icon.mr-50(icon='ChevronRightIcon')
                | Fournisseur
              span
                | {{ productProvider.company.name }}
                feather-icon.cursor-pointer.ml-50(icon='ExternalLinkIcon' @click='editCompany(productProvider.company.id)')
            ul.listTab
              li(v-if='productProvider.company.email')
                span Email
                span {{ productProvider.company.email }}
              li(v-if='productProvider.company.phoneNumber')
                span T&eacute;l&eacute;phone
                span {{ productProvider.company.phoneNumber }}
              li(v-if='productProvider.company.secondaryPhoneNumber')
                span T&eacute;l secondaire
                span {{ productProvider.company.secondaryPhoneNumber }}
            vs-divider
          div
            h4.mb-1.text-primary.d-flex.justify-content-start
              span(style='min-width: min-content')
                feather-icon.mr-50(icon='EditIcon')
                | Informations
            ul.listTab
              li
                span R&eacute;f&eacute;rence
                span {{ formatHTMLToString(productProvider.reference) }}
              li(v-if='productProvider.label')
                span Libell&eacute;
                span {{ formatHTMLToString(productProvider.label) }}
              li
                span D&eacute;signation
                span
                  | {{
                  | formatHTMLToString(productProvider.description)
                  | }}
              li(v-if='computedEcoContribution && computedEcoContribution > 0')
                span Eco-contribution
                span {{ computedEcoContribution }}
              li(v-if='!productProvider.useDegressivePurchaseRate')
                span Prix d&apos;achat HT
                span {{ computedNetPrice }}
            vs-divider(v-if='productProvider.useDegressivePurchaseRate')
          div(v-if='productProvider.useDegressivePurchaseRate')
            h4.mb-1.text-primary.d-flex.justify-content-start
              span(style='min-width: min-content')
                feather-icon(icon='BookOpenIcon')
                  | Tarifs d&eacute;gressifs
            ul.arrayTab
              li
                span Quantit&eacute;
                span PA. HT
                span Remise
              li(v-for='(item, index) in productProvider.degressivePurchaseRates')
                span
                  | Entre {{ item.quantityMin }}
                  | {{
                  | item.quantityMax ? &quot;et &quot; + item.quantityMax : &quot;ou plus&quot;
                  | }}
                span {{ formatCurrency(item.price) }}
                span {{ formatPercent(item.discount) }}
        div(v-if='tabActive == "sales"')
          .d-flex.mb-1
            img.thumbnail(v-if="product.urlImage" :src="product.urlImage")
            div.d-flex.flex-column
              span.w-100.font-weight-bold {{ product.label }}
              span.w-100 {{ formatHTMLToString(product.description) }}  
          ul.listTab
            li(v-if="product.reference")
              span Référence
              span {{ product.reference }}
            li(v-if="product.saleCategory")
              span Catégorie de vente
              span {{ product.saleCategory.label }}
            li(v-if="product.productCategories")
              span Catégorie d'article
              span {{ computedProductCategories }}
            li(v-if="product.unit")
              span Unité
              span {{ product.unit.label }}
            li(v-if="product.unit")
              span Quantité par défaut
              span {{ product.quantity }}
            li(v-if="product.priceHT && !product.useDegressiveSaleRate")
              span Prix Vente U. HT
              span {{ formatCurrency(product.priceHT) }}
          div(v-if='product.useDegressiveSaleRate')
            h5.my-1.text-primary.d-flex.justify-content-start
              span(style='min-width: min-content;')
                feather-icon(icon='ChevronRightIcon')
                | Tarifs dégressifs
            ul.arrayTab
              li
                span Quantité
                span PA. U HT
                span Remise
              li(v-for='(item, index) in product.degressiveSalesRates')
                span
                  | Entre {{ item.quantityMin }} {{ item.quantityMax ? &apos;et &apos; + item.quantityMax : &apos;ou plus&apos; }}
                span {{ formatCurrency(item.price) }}
                span {{ formatPercent(item.discount) }}


</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import { EProductLabelType } from "@/types/api-orisis/enums/enums";
import { ProductProviderDefaultProperties } from "@/types/api-orisis/models/ProductProviderModel";
import {
  formatCurrency,
  formatPercent,
  formatHTMLToString,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { productTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      ProductProviderDefaultProperties,
      archived: false,
      product: null,
      tabActive: "purchases",
    };
  },
  computed: {
    ...mapGetters([
      "isCreatingProductProvider",
      "isLoadingProductProvider",
      "isDeletingProductProvider",
    ]),
    computedNetPrice() {
      return formatCurrency(this.productProvider.netPrice);
    },
    computedGrossMarginHt() {
      return formatCurrency(this.productProvider.grossMarginHT);
    },
    computedMarginRate() {
      return formatPercent(this.productProvider.marginRate);
    },
    computedEcoContribution() {
      return formatCurrency(this.productProvider.ecoContribution);
    },
    computedProductCategories() {
      return this.product.productCategories
        .map((item) => item.label)
        .join(", ");
    },
    productProvider: {
      get() {
        return this.$store.getters.productProvider;
      },
      set(val) {
        this.$store.commit("SET_PRODUCT_PROVIDER", val);
      },
    },
  },
  methods: {
    ...mapActions([
      "deleteProductProvider",
      "archiveProductProviders",
      "getProductProviderById",
      "restoreProductProviders",
      "getProductProviderArchivedById",
      "getProduct",
    ]),
    formatCurrency,
    formatPercent,
    productTypeTranslate,
    formatHTMLToString,
    getProductProviderDetails(id) {
      this.archived = false;
      if (id) {
        this.getProductProviderById({
          productProviderId: id,
        }).then((res) => {
          this.productProvider = res;
          if (this.productProvider.productId) {
            this.getProduct({ productId: this.productProvider.productId }).then(
              (product) => {
                this.product = product;
              }
            );
          }
        });
      } else {
        this.productProvider = this.ProductProviderDefaultProperties({});
      }
    },
    getProductProviderArchivedDetails(id) {
      this.archived = true;
      if (id) {
        this.getProductProviderArchivedById({
          productProviderId: id,
        }).then((res) => {
          this.productProvider = res;
        });
      }
    },
    async editProductProvider(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-product-provider",
        params: {
          id: id,
          title: "Edit produit fournisseur",
          tips: "Editer le produit fournisseur",
          routeOrigine: "product-provider",
        },
      });
    },
    archiveProductProviderLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce produit fournisseur en consultant la liste de vos archives.",
          {
            title: "Êtes-vous sûr de vouloir archiver le produit fournisseur ?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveProductProviders({
              productProviderIds: [id],
            }).then(() => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    restoreProductProviderLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title:
              "Êtes-vous sûr de vouloir restaurer le produit fournisseur ?",
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreProductProviders({
              productProviderIds: [id],
            }).then(() => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    deleteProductProviderLocal(productProvider) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir supprimer le produit fournisseur ?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteProductProvider({
              productProviderIds: [productProvider.id],
            }).then(() => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    async editCompany(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title:
            "Edit : " +
            (this.productProvider.company.name
              ? this.productProvider.company.name
              : this.productProvider.company.firstName +
                " " +
                this.productProvider.company.lastName),
          tips:
            "Editer le fournisseur : " +
            (this.productProvider.company.name
              ? this.productProvider.company.name
              : this.productProvider.company.firstName +
                " " +
                this.productProvider.company.lastName),
          routeOrigine: " product-provider",
        },
      });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style scoped>
.content-line-sidebar-product {
  display: flex;
  justify-content: space-between;
}
</style>
