<template lang="pug">
.h-100.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :title="title" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow")
    .d-flex.w-100.h-100(style="overflow: hidden;")
        .text-center.flex-center.w-100(v-if='!headerLoaded')
            .loading-bg-inner
                .loader
                    .outer
                    .middle
                    .inner
            .mt-5
                br
                br
                br
                | Chargement de la liste des contacts...
        sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :editSettings='editSettings'  :allowGrouping="allowGrouping" :gridData="companyFormContacts" :name="name" :headerData="headerData" @rowClicked="onRowClicked"  @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @actionBegin="actionBegin" :archive="archive" :archiveMode="archiveMode" @deleteButtonClicked="deleteButtonClicked")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ECivility } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  props: {
    companyId: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      name: "contact",
      uid: "companyContacts",
      title: "Contacts",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher un contact",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouveau contact",
        titleEdit: "Modifier contact",
        titleField: "label",
      },
      allowGrouping: false,

      companyForm: {},
      companyFormContacts: [],
      dataOrigine: {},

      optionCivility: [
        {
          label: "M. et Mme",
          value: ECivility.MonsieurMadame,
        },
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },

        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["company", "contactsList", "workspaceSelected"]),
  },
  async created() {
    this.companyForm = structuredClone(this.company);
    this.companyForm.contactIds = this.company.contacts
      ? this.company.contacts.map((elem) => {
          return elem.contact.id;
        })
      : null;
    this.companyType = this.company.companyType;
    this.companyFormContacts = structuredClone(
      this.company.contacts
        ? this.company.contacts.map((el) => {
            return { ...el.contact, label: el.label };
          })
        : null
    );
    this.dataOrigine = JSON.stringify(this.companyForm);

    await this.getContacts({});
    this.setHeaderData();
  },
  methods: {
    ...mapActions([
      "getContacts",
      "createContact",
      "updateContact",
      "updateCompany",
    ]),
    formatCurrency,
    setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
          showInColumnChooser: false,
        },
        {
          type: "string",
          field: "label",
          headerText: "Libellé",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "civility",
          filterTemplate: "civilityFilterTemplate",
          headerText: "Civilité",
          template: "civilityTemplate",
          width: 100,
          maxWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          foreignKeyValue: "label",
          dataSource: this.optionCivility,
          defaultValue: 1,
          edit: {
            source: this.optionCivility,
            fields: { text: "label", value: "value" },
            type: "dropdown",
            name: "contactCivility",
            origine: "companyContacts",
          },
        },
        {
          type: "string",
          field: "lastName",
          headerText: "Nom",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          validationRules: { required: true },
          edit: {
            source: this.contactsList,
            fields: { text: "lastName", value: "lastName" },
            type: "autocomplete",
            name: "contactLastName",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "string",
          field: "firstName",
          headerText: "Prénom",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          edit: {
            source: this.contactsList,
            fields: { text: "firstName", value: "firstName" },
            type: "autocomplete",
            name: "contactFirstName",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "string",
          field: "phoneNumber",
          headerText: "Téléphone",
          width: 120,
          minWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          edit: {
            source: this.contactsList,
            fields: { text: "phoneNumber", value: "phoneNumber" },
            type: "autocomplete",
            name: "contactPhoneNumber",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "string",
          field: "secondaryPhoneNumber",
          headerText: "Tél secondaire",
          width: 120,
          minWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          edit: {
            type: "textbox",
            name: "contactSecondaryPhoneNumber",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "string",
          field: "email",
          headerText: "E-mail",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          edit: {
            type: "textbox",
            name: "contactEmail",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "number",
          field: "id",
          headerText: "ID",
          foreignKeyField: "id",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          defaultValue: 0,
          visible: false,
          edit: {
            type: "textbox",
            name: "contactId",
            origine: "companyContacts",
          },
        },
      ];
      this.headerLoaded = true;
    },
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        let labelContact = args.data.label;
        this.companyFormContacts.push(args.data);
        if (args.data.id > 0) {
          await this.updateContact({ contact: args.data }).then((res) => {
            this.companyFormContacts.splice(
              this.companyFormContacts.length - 1,
              1,
              { ...res, label: labelContact }
            );
            this.$refs.overviewgrid.$refs.overviewgrid.refresh();
          });
        } else {
          await this.createContact({ contact: args.data }).then((res) => {
            this.companyFormContacts.splice(
              this.companyFormContacts.length - 1,
              1,
              { ...res, label: labelContact }
            );
            this.$refs.overviewgrid.$refs.overviewgrid.refresh();
          });
        }
        this.updateCompany({
          company: {
            ...this.companyForm,
            contacts: this.companyFormContacts
              ? this.companyFormContacts.map((el) => {
                  return { contactId: el.id, label: el.label };
                })
              : [],
          },
        }).then((res) => {
          this.company.contacts = this.companyFormContacts
            ? this.companyFormContacts.map((el) => {
                return { contact: el, label: el.label };
              })
            : [];
        });
      }
      if (args.requestType === "save" && args.action === "edit") {
        this.companyFormContacts.splice(args.rowIndex, 1, args.data);
        await this.updateContact({ contact: args.data });

        this.updateCompany({
          company: {
            ...this.companyForm,
            contacts: this.companyFormContacts
              ? this.companyFormContacts.map((el) => {
                  return { contactId: el.id, label: el.label };
                })
              : [],
          },
        }).then((res) => {
          this.company.contacts = this.companyFormContacts
            ? this.companyFormContacts.map((el) => {
                return { contact: el, label: el.label };
              })
            : [];
        });
      }
    },
    clickAction() {
      this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
    },
    onRowClicked(event) {
      //this.$refs.overviewgrid.selectRow(event.rowIndex, false)
      // this.$refs.overviewgrid.$refs.overviewgrid.startEdit();
    },
    async deleteButtonClicked(args) {
      this.companyFormContacts.splice(
        this.companyFormContacts.findIndex(
          (elem) => elem.id == args.rowData.id
        ),
        1
      );
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      this.updateCompany({
        company: {
          ...this.companyForm,
          contacts: this.companyFormContacts
            ? this.companyFormContacts.map((el) => {
                return { contactId: el.id, label: el.label };
              })
            : [],
        },
      }).then((res) => {
        this.company.contacts = this.companyFormContacts
          ? this.companyFormContacts.map((el) => {
              return { contact: el, label: el.label };
            })
          : [];
      });
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
