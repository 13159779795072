<template lang="pug">
  .h-100.d-flex.flex-column
    HeaderGrid(:name="name" userFilter="companyDeliveryFormProviderFilters" :dateRangeDefault="dateRangeDefault" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch")
    StatCards
        div.cursor-pointer.pl-1.col-card-list-stats(style='max-width: 250px;' @click="changeTab('all')")
          .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
            .card-list-stats-title
              div
                span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
                span.card-list-stats-title-text TOUT
              .card-list-stats-title-badge.bg-primary.text-white {{ getCountDataByStatus("all") }}
            .card-sum
              | {{ getSumDataByStatus("all") }} 
              span {{ tvaMode }}
        div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" style='max-width: 250px;' @click="changeTab(status.value)")
          .card-list-stats(:class="[tabActive == status.value ? 'current' : '', 'border-'+status.class]")
            .card-list-stats-title
              div
                span.card-list-stats-title-badge.material-icons.mr-50(:class="'text-'+status.class") {{ status.icon }}
                span.card-list-stats-title-text {{ status.label }}
              .card-list-stats-title-badge.text-white(:class="'bg-'+status.class") {{ getCountDataByStatus(status.value) }}
            .card-sum
              | {{ getSumDataByStatus(status.value) }} 
              span {{ tvaMode }}
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingDeliveryFormsListByCompany || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des bons de sortie...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-delivery-form-content(ref='sidebarDeliveryFormContent' @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
    popup-new-delivery-form(ref='popupNewDeliveryForm' :companyId="companyId")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { deliveryFormStatus } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import StatCards from "@/components/global/grid/StatCards.vue";

import SidebarDeliveryFormContent from "@/components/purchase/delivery-form/SidebarDeliveryFormContent";
import PopupNewDeliveryForm from "@/components/purchase/delivery-form/PopupNewDeliveryForm.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarDeliveryFormContent,
    PopupNewDeliveryForm,
    SyncGrid,
    StatCards,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "deliveryForm",
      uid: "companyDeliveryForms",
      statusList: deliveryFormStatus.filter((el) => !el.notUse),
      headerData: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      documentSelected: {},

      tvaMode: "HT",
      dateRanger: true,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: true,
      searchPlaceholder: "Rechercher un bon de sortie",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "deliveryType",
        headerText: "Type",
        filterTemplate: "deliveryTypeFilterTemplate",
        width: 200,
        mminWidth: 200,
        template: "typeTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 100,
        maxWidth: 100,
        template: "statusTemplate",
        nature: "deliveryFormStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "documentReference",
        headerText: "Numéro",
        width: 160,
        maxWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        allowGrouping: false,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "documentDate",
        headerText: "Date",
        width: 95,
        maxWidth: 95,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "userId",
        filterTemplate: "userFilterTemplate",
        headerText: "Traité par",
        maxWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        valueAccessor: "user",
      },
      {
        type: "string",
        field: "companyCustomerAddress",
        headerText: "Livré à",
        maxWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        valueAccessor: "companyCustomerAddress",
        clipMode: "EllipsisWithTooltip",
      },
      {
        type: "string",
        field: "companyCustomerName",
        headerText: "Client",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "affairName",
        headerText: "Affaire",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "storageLocationLabel",
        headerText: "Point de livraison",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "deliveredQuantity",
        headerText: "Quantité",
        maxWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "deliveryFormTags",
        headerText: "Tags",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "tagsTemplate",
        unavailableExport: true,
      },
      {
        type: "number",
        field: "valueHt",
        headerText: "Valeur HT",
        maxWidth: 150,
        textAlign: "right",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        allowGrouping: false,
        allowAggregate: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "isLoadingDeliveryFormsListByCompany",
      "institutionSelected",
      "workspaceSelected",
      "institutionsList",
      "deliveryFormsListByCompany",
      "userParametersList",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.$store.getters[this.name + "sListByCompany"].filter(
          (elem) =>
            elem[this.name + "Tags"].find((tag) =>
              this.tagsSelected.includes(tag.id)
            )
        );
      } else {
        return this.$store.getters[this.name + "sListByCompany"];
      }
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.tagsSelected.length) {
            return this.$store.getters[this.name + "sListByCompany"].filter(
              (elem) =>
                elem[this.name + "sListByCompany"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                )
            );
          } else {
            return this.$store.getters[this.name + "sListByCompany"];
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.tagsSelected.length) {
            return this.$store.getters[this.name + "sListByCompany"].filter(
              (elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                ) &&
                (statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive)
            );
          } else {
            return this.$store.getters[this.name + "sListByCompany"].filter(
              (elem) =>
                statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive
            );
          }
        }
      } else {
        return [];
      }
    },
  },
  props: {
    companyId: {
      default: null,
      required: true,
    },
  },
  async created() {
    let filter = this.userParametersList.find(
      (el) => el.key == "companyDeliveryFormProviderFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
    await this.getCompanies({});
    await this.getCollaborators({});
    await this.getDeliveryFormTags({});
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getCollaborators",
      "getDeliveryForms",
      "getDeliveryFormTags",
    ]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getDeliveryForms({
          companyId: this.companyId,
          dateFrom: dateRange.startDate,
          dateTo: dateRange.endDate,
          route: "company",
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.selectInstitution = this.institutionSelected;
      this.$refs.popupNewDeliveryForm.toggleModal();
    },
    async setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: true,
          showInColumnChooser: false,
        },
        {
          type: "string",
          field: "deliveryType",
          headerText: "Type",
          filterTemplate: "deliveryTypeFilterTemplate",
          width: 200,
          mminWidth: 200,
          template: "typeTemplate",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "status",
          headerText: "Statut",
          width: 100,
          maxWidth: 100,
          template: "statusTemplate",
          nature: "deliveryFormStatus",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          filterTemplate: "statusFilterTemplate",
        },
        {
          type: "string",
          field: "documentReference",
          headerText: "Numéro",
          width: 160,
          maxWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          allowGrouping: false,
        },
        {
          type: "date",
          format: "formatDateOptions",
          field: "documentDate",
          headerText: "Date",
          width: 95,
          maxWidth: 95,
          template: "dateYmdTemplate",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "number",
          field: "userId",
          filterTemplate: "userFilterTemplate",
          headerText: "Traité par",
          maxWidth: 150,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "user",
        },
        {
          type: "string",
          field: "companyCustomerAddress",
          headerText: "Livré à",
          maxWidth: 150,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "companyCustomerAddress",
          clipMode: "EllipsisWithTooltip",
        },
        {
          type: "string",
          field: "companyCustomerName",
          headerText: "Client",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "affairName",
          headerText: "Affaire",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "storageLocationLabel",
          headerText: "Point de livraison",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "number",
          field: "deliveredQuantity",
          headerText: "Quantité",
          maxWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "deliveryFormTags",
          headerText: "Tags",
          width: "auto",
          minWidth: 200,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "tagsTemplate",
          unavailableExport: true,
        },
        {
          type: "number",
          field: "valueHt",
          headerText: "Valeur HT",
          maxWidth: 150,
          textAlign: "right",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          format: "formatCurrency",
          allowGrouping: false,
          allowAggregate: true,
        },
      ];
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          sum += obj["valueHt"];
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          )
          .forEach((obj) => {
            sum += obj["valueHt"];
          });
      }
      return this.formatCurrency(sum);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarDeliveryFormContent"].getDocumentDetails(
        event.rowData.id
      );
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
