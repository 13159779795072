<template lang="pug">
  b-col.quote-card.w-100.my-1(cols="12")
    b-form-group(v-if="!selectedOrderFormProvider" label-for='OrderFormProvider')
      validation-provider(#default='{ errors }' name='OrderFormProvider')
        v-select#orderFormProvider(ref="selectOrderFormProvider" v-if="!selectedOrderFormProvider" :loading='isLoadingOrderFormProvidersList' label="documentReference" :state='errors.length > 0 ? false : null' v-model="selectedOrderFormProvider" @input='setValue' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='options' :placeholder='placeholder')
          template(v-slot:no-options='')
            template  Aucun r&eacute;sultat trouv&eacute;
        small.text-danger {{ errors[0] }}
    .card-info(v-else @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
      div
        .card-tools.pr-0(v-if="showCardTool")
          feather-icon(icon="XIcon" size="18" @click="resetValue"  v-b-tooltip.hover.top="'Changer de commande fournisseur'")
        p.text-primary.font-weight-bold Commande fournisseur sélectionnée :
        div(v-if="selectedOrderFormProvider.documentReference != null")
          span.font-weight-bold {{ selectedOrderFormProvider.documentReference  }}
        div(v-if="selectedOrderFormProvider.companyCustomerName != null")
          span Client : {{ selectedOrderFormProvider.companyCustomerName }}
        div(v-if="selectedOrderFormProvider.affairName != null")
          span Affaire : {{ selectedOrderFormProvider.affairName }}
        div(v-if="selectedOrderFormProvider.companyProviderName != null")
          span Fournisseur : {{ selectedOrderFormProvider.companyProviderName }}
        div(v-if="selectedOrderFormProvider.storageLocationLabel != null")
          span Point de stockage : {{ selectedOrderFormProvider.storageLocationLabel }}
</template>

<script>
import vSelect from "vue-select";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showCardTool: false,
      required,
      selectedOrderFormProvider: null,
    };
  },
  created() {
    this.getOrderForms({ dateFrom: null, dateTo: null });
  },
  computed: {
    ...mapGetters(["isLoadingOrderFormProvidersList"]),
  },
  methods: {
    ...mapActions(["getOrderForms"]),
    setValue(value) {
      this.$emit("setValue", value);
    },
    resetValue() {
      this.selectedOrderFormProvider = null;
      this.setValue(this.selectedOrderFormProvider);
    },
  },
};
</script>

<style scoped>
#button-section {
  display: flex;
  justify-content: flex-end;
}
#button-section button {
  margin-left: 10px;
  width: 30em;
}
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}

.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}

.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>
