<template lang="pug">
.p-0
  .content-loader-center.m-0.h-100(v-if='isLoadingCompany')
    .text-center.flex-center
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement des détails de la société...
  div.details-container(v-else)
    div.d-flex.content-scrollable-sticky.h-100.justify-content-start.w-100
        #nav-sticky-vertical.cell-left.text-center.h-100(ref="navStickyVertical" style='position: relative;overflow-y: auto; background: #EEF1F6; overflow-x: hidden; width: 240px; min-width: 240px; max-width: 240px;')
          .close-btn.d-flex.justify-content-end.pt-1.px-1
            span.material-icons-outlined(@click="closeMenuSidebar" style="cursor: pointer;")
              | chevron_left
          .head
            .d-flex.justify-content-between.px-1
              div
                h3.text-nowrap Société : {{company.code}}
              //- feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24')
            .d-flex.justify-content-between.pb-1.px-1
              .d-flex
                span.text-nowrap(style="white-space: break-spaces;") {{company.name}}
              .end
          ul
            li.title
              vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
                span.text-primary Dossier
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('companyDescription')" :class="tabActive == 'companyDescription' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | assignment
                span.pl-1.text-nowrap Description
            li(v-if="checkFunctionUser(1, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('companyAffairs')" :class="tabActive == 'companyAffairs' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | list_alt
                span.pl-1.text-nowrap Affaires 
            li(v-if="checkFunctionUser(8, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('companyContacts')" :class="tabActive == 'companyContacts' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | import_contacts
                span.pl-1.text-nowrap Contacts
            li(v-if="company.companyType.id == 4 && checkFunctionUser(74, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('productsProvider')" :class="tabActive == 'productsProvider' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | home_repair_service
                span.pl-1.text-nowrap Produits fournisseur
            li.title(v-if="checkFunctionUser(100, 'all')")
              vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
                span.text-primary CRM
            li(v-if="checkFunctionUser(100, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('companyActivities')" :class="tabActive == 'companyActivities' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | list_alt
                span.pl-1.text-nowrap Activités commerciales
            li(v-if="checkFunctionUser(100, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('companyReminders')" :class="tabActive == 'companyReminders' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | list_alt
                span.pl-1.text-nowrap Relances
            li.title(v-if="checkFunctionUserByFunctionIdsAndActions({functionIds: [75, 108, 66, 51, 24, 30, 12, 22, 17], actions: ['all']})")
              vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
                span.text-primary Documents
            li(v-if="company.companyType.id != 4 && company.companyType.id != 8 && checkFunctionUser(75, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('quotes')" :class="tabActive == 'quotes' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | request_quote
                span.pl-1.text-nowrap Devis
            li(v-if="company.companyType.id != 4 && company.companyType.id != 8 && checkFunctionUser(108, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('orderFormCustomer')" :class="tabActive == 'orderFormCustomer' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | checklist_rtl
                span.pl-1.text-nowrap Commandes client
            li(v-if="company.companyType.id != 4 && company.companyType.id != 8 && checkFunctionUser(66, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('invoices')" :class="tabActive == 'invoices' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | description
                span.pl-1.text-nowrap Factures
            li(v-if="company.companyType.id != 4 && company.companyType.id != 8  && checkFunctionUser(51, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('credits')" :class="tabActive == 'credits' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | indeterminate_check_box
                span.pl-1.text-nowrap Avoirs
            li(v-if="company.companyType.id != 7 && checkFunctionUser(24, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('orderFormProvider')" :class="tabActive == 'orderFormProvider' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | insert_drive_file
                span.pl-1.text-nowrap Commandes fournisseur
            li(v-if="company.companyType.id != 7 && checkFunctionUser(30, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('receiptFormProvider')" :class="tabActive == 'receiptFormProvider' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | download
                span.pl-1.text-nowrap Bons de réception
            li(v-if="company.companyType.id != 4 && company.companyType.id != 7 && company.companyType.id != 8 && checkFunctionUser(12, 'all')") 
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('deliveryForm')" :class="tabActive == 'deliveryForm' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | upload
                span.pl-1.text-nowrap Bons de sortie
            li(v-if="company.companyType.id == 4 || company.companyType.id == 8 && checkFunctionUser(22, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('invoicesProvider')" :class="tabActive == 'invoicesProvider' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | description
                span.pl-1.text-nowrap Factures fournisseur
            li(v-if="company.companyType.id == 4 || company.companyType.id == 8 && checkFunctionUser(17, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('creditsProvider')" :class="tabActive == 'creditsProvider' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | indeterminate_check_box
                span.pl-1.text-nowrap Avoirs fournisseur
        #content-wrapper.d-flex.flex-column.h-100(style="width: calc(100% - 240px)" ref="contentWrapper")
          .h-100.w-100
            CompanyDescription(v-if="tabActive=='companyDescription'" :companyId='company.id')
            CompanyRemindersList(v-else-if="tabActive=='companyReminders'" :companyId='company.id')
            CompanyActivitiesList(v-else-if="tabActive=='companyActivities'" :companyId='company.id')
            CompanyContactsList(v-else-if="tabActive=='companyContacts'" :companyId='company.id')
            CompanyQuotesList(v-else-if="tabActive=='quotes'" :companyId='company.id')
            CompanyInvoicesList(v-else-if="tabActive=='invoices'" :companyId='company.id')
            CompanyCreditsList(v-else-if="tabActive=='credits'" :companyId='company.id')
            CompanyOrderFormsCustomerList(v-else-if="tabActive=='orderFormCustomer'" :companyId='company.id')
            CompanyOrderFormsProviderList(v-else-if="tabActive=='orderFormProvider'" :companyId='company.id')
            CompanyReceiptFormsProviderList(v-else-if="tabActive=='receiptFormProvider'" :companyId='company.id')
            CompanyDeliveryFormsList(v-else-if="tabActive=='deliveryForm'" :companyId='company.id')
            CompanyInvoicesProviderList(v-else-if="tabActive=='invoicesProvider'" :companyId='company.id')
            CompanyCreditsProviderList(v-else-if="tabActive=='creditsProvider'" :companyId='company.id')
            CompanyAffairsList(v-else-if="tabActive=='companyAffairs'" :companyId='company.id')
            CompanyProductsList(v-else-if="tabActive=='productsProvider'" :companyId='company.id')

</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import {
  checkFunctionUser,
  checkFunctionUserByFunctionIdsAndActions,
} from "@/auth/utils.ts";

import GedViewer from "@/components/ged/GedViewer";

import CompanyDescription from "@/components/directory/company/CompanyDescription";
import CompanyContactsList from "@/components/directory/company/CompanyContactsList";
import CompanyRemindersList from "@/components/directory/company/CompanyRemindersList.vue";
import CompanyActivitiesList from "@/components/directory/company/CompanyActivitiesList.vue";
import CompanyAffairsList from "@/components/directory/company/CompanyAffairsList.vue";
import CompanyProductsList from "@/components/directory/company/CompanyProductsProviderList.vue";

import CompanyQuotesList from "@/components/directory/company/CompanyQuotesList";
import CompanyOrderFormsCustomerList from "@/components/directory/company/CompanyOrderFormCustomersList";
import CompanyInvoicesList from "@/components/directory/company/CompanyInvoicesList";
import CompanyCreditsList from "@/components/directory/company/CompanyCreditsList";
import CompanyOrderFormsProviderList from "@/components/directory/company/CompanyOrderFormProviderList";
import CompanyDeliveryFormsList from "@/components/directory/company/CompanyDeliveryFormList";
import CompanyReceiptFormsProviderList from "@/components/directory/company/CompanyReceiptFormProviderList";
import CompanyInvoicesProviderList from "@/components/directory/company/CompanyInvoicesProviderList";
import CompanyCreditsProviderList from "@/components/directory/company/CompanyCreditProviderList";

import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,
    CompanyDescription,
    CompanyContactsList,
    CompanyRemindersList,
    CompanyActivitiesList,
    CompanyAffairsList,
    CompanyProductsList,

    CompanyQuotesList,
    CompanyOrderFormsCustomerList,
    CompanyInvoicesList,
    CompanyCreditsList,
    CompanyOrderFormsProviderList,
    CompanyDeliveryFormsList,
    CompanyReceiptFormsProviderList,
    CompanyInvoicesProviderList,
    CompanyCreditsProviderList,
  },
  props: {
    id: {
      default: null,
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async created() {
    if (this.id !== "" && this.id !== 0 && this.company.id !== this.id) {
      await this.getCompanyById({ companyId: this.id });
    }
    await this.getCompanies({});
    await this.getCollaborators({});
  },
  data() {
    return {
      tabActive: "companyDescription",
    };
  },
  computed: {
    ...mapGetters(["isLoadingCompany", "workspaceSelected"]),
    company: {
      get() {
        return this.$store.getters.company;
      },
      set(value) {
        return this.$store.commit("SET_COMPANY", value);
      },
    },
  },
  methods: {
    ...mapActions(["getCompanyById", "getCompanies", "getCollaborators"]),
    checkFunctionUser,
    checkFunctionUserByFunctionIdsAndActions,
    formatDate,
    activeTab(tab) {
      this.tabActive = tab;
    },
    checkModule(moduleId) {
      return true;
    },
    async editLease(id) {
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title: "Edit : " + this.company.name,
          tips: "Editer le bail : " + this.company.name,
          routeOrigine: "company",
        },
      });
    },
    closeMenuSidebar() {
      this.$refs.navStickyVertical.classList.toggle("sidebar-closed");
      this.$refs.contentWrapper.classList.toggle("sidebar-is-closed");
    },
  },
};
</script>

<style lang="scss">
.con-vs-tabs .con-slot-tabs {
  height: 100%;
  .vs-tabs--content {
    padding: 0px;
    height: 100%;
    > div {
      padding: 0px;
      height: 100%;
    }
  }
}
.sidebar-closed,
.sidebar-closed .title span {
  transition-delay: 0.5s;
  transition: all 0.5s ease-in-out;
}

.sidebar-closed .title span {
  font-size: 0;
}
.sidebar-closed .title .vs-divider {
  margin: 0 !important;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.sidebar-closed .close-btn span {
  transform: rotate(180deg);
}

.sidebar-closed .close-btn {
  position: relative;
}

.head {
  padding-top: 1.2rem;
  & h3 {
    font-size: 1.2rem;
  }
}

.sidebar-closed .head {
  padding-top: 0;
}

.sidebar-closed .head,
.sidebar-closed .title {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.sidebar-closed {
  width: 52px !important;
  min-width: 52px !important;
  max-width: 52px !important;
}

#content-wrapper {
  transition: all 0.5s ease-in-out;
}

#content-wrapper.sidebar-is-closed {
  transition: all 0.5s ease-in-out;
  width: calc(100% - 52px) !important;
}

#nav-sticky-vertical,
.title span,
.head,
.title,
.title .vs-divider {
  transition: all 0.5s ease-in-out;
}

@keyframes fadeOut {
  from {
    max-height: 100%;
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
  to {
    max-height: 0%;
    width: 0%;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
