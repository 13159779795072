<template lang="pug">
  .h-100.d-flex.flex-column
    HeaderGrid(:name="name" userFilter="companyInvoicesProviderFilters" :dateRangeDefault="dateRangeDefault" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" addButtonLabel="Importer" :indexSearch="indexSearch")
    StatCards
      div.cursor-pointer.pl-1.col-card-list-stats(style='max-width: 250px;' @click="changeTab('all')")
        .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
              span.card-list-stats-title-text TOUT
            .card-list-stats-title-badge.bg-primary.text-white
              span {{ getCountDataByStatus("all") }}
          .card-sum
            | {{ getSumDataByStatus("all") }} 
            span {{ tvaMode }}
      div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)"  style='max-width: 250px;' )
        .card-list-stats(:class="[tabActive == status.value ? 'current' : '', 'border-'+status.class]")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.mr-25(:class="'text-'+status.class") {{ status.icon }}
              span.card-list-stats-title-text {{ status.label }}
            .card-list-stats-title-badge.text-white(:class="'bg-'+status.class")
              span {{ getCountDataByStatus(status.value) }}
          .card-sum
            | {{ getSumDataByStatus(status.value) }} 
            span {{ tvaMode }}
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingInvoiceProvidersListByProvider || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des factures fournisseur...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-invoice-provider-content(ref='sidebarInvoiceProviderContent' @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
    popup-import-invoices-provider(ref='PopupImportInvoicesProvider')
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { invoiceProviderStatus } from "@/types/api-orisis/enums/enums";
import StatCards from "@/components/global/grid/StatCards.vue";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarInvoiceProviderContent from "@/components/purchase/invoice-provider/SidebarInvoiceProviderContent";
import PopupImportInvoicesProvider from "@/components/purchase/invoice-provider/PopupImportInvoicesProvider.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarInvoiceProviderContent,
    PopupImportInvoicesProvider,
    SyncGrid,
    StatCards,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "invoicesProvider",
      uid: "companyInvoicesProviders",
      statusList: invoiceProviderStatus.filter((el) => !el.notUse),
      headerData: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],

      tvaMode: "HT",
      dateRanger: true,
      tvaModeButton: false,
      addButton: false,
      searchButton: true,
      tagguerButton: true,
      searchPlaceholder: "Rechercher une facture fournisseur",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 110,
        maxWidth: 110,
        template: "statusTemplate",
        nature: "invoiceProviderStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "documentReference",
        headerText: "Numéro",
        width: 160,
        maxWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "documentDate",
        headerText: "Date",
        width: 95,
        maxWidth: 95,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "provider.name",
        headerText: "Fournisseur",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "description",
        headerText: "Objet",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowGrouping: false,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filter: { type: "Menu", operator: "contains" },
        clipMode: "EllipsisWithTooltip",
        template: "stripTagsTemplate",
      },
      {
        type: "string",
        field: "invoiceProviderTags",
        headerText: "Tags",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "tagsTemplate",
        unavailableExport: true,
      },
      {
        type: "number",
        field: "totalHT",
        headerText: "Total HT",
        maxWidth: 150,
        textAlign: "right",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        allowGrouping: false,
        allowAggregate: true,
      },
      {
        type: "number",
        field: "documentTva",
        headerText: "Total TVA",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: false,
        template: "tvaProviderTemplate",
        format: "formatCurrency",
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
      {
        type: "number",
        field: "totalTTC",
        headerText: "Total TTC",
        maxWidth: 150,
        textAlign: "right",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        allowGrouping: false,
        allowAggregate: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "isLoadingInvoiceProvidersListByProvider",
      "invoicesProvidersListByProvider",
      "company",
      "userParametersList",
    ]),

    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.$store.getters[this.name + "sListByProvider"].filter(
          (elem) =>
            elem[this.name + "Tags"].find((tag) =>
              this.tagsSelected.includes(tag.id)
            )
        );
      } else {
        return this.$store.getters[this.name + "sListByProvider"];
      }
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.tagsSelected.length) {
            return this.$store.getters[this.name + "sListByProvider"].filter(
              (elem) =>
                elem[this.name + "sListByProvider"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                )
            );
          } else {
            return this.$store.getters[this.name + "sListByProvider"];
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.tagsSelected.length) {
            return this.$store.getters[this.name + "sListByProvider"].filter(
              (elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                ) &&
                (statusInfos.query
                  ? statusInfos.query.includes(elem.invoiceProviderStatus)
                  : elem.invoiceProviderStatus === this.tabActive)
            );
          } else {
            return this.$store.getters[this.name + "sListByProvider"].filter(
              (elem) =>
                statusInfos.query
                  ? statusInfos.query.includes(elem.invoiceProviderStatus)
                  : elem.invoiceProviderStatus === this.tabActive
            );
          }
        }
      } else {
        return [];
      }
    },
  },
  props: {
    companyId: {
      default: null,
      required: true,
    },
  },
  async created() {
    let filter = this.userParametersList.find(
      (el) => el.key == "companyInvoicesProviderFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
    await this.getCompanies({});
    this.getInvoiceProviderTags({});
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getInvoicesProviders",
      "getInvoiceProviderTags",
    ]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getInvoicesProviders({
          providerIds: this.companyId,
          dateFrom: dateRange.startDate,
          dateTo: dateRange.endDate,
          route: "provider",
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$refs.PopupImportInvoicesProvider.toggleModal();
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.invoiceProviderStatus)
            : elem.invoiceProviderStatus === status
        ).length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          sum += obj.invoiceProviderRepartitions.reduce((acc, current) => {
            if (current.companyId.toString() === this.companyId.toString()) {
              return acc + current.priceHt;
            } else {
              return acc;
            }
          }, 0);
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.invoiceProviderStatus)
              : elem.invoiceProviderStatus === status
          )
          .forEach((obj) => {
            sum += obj.invoiceProviderRepartitions.reduce((acc, current) => {
              if (current.companyId.toString() === this.companyId.toString()) {
                return acc + current.priceHt;
              } else {
                return acc;
              }
            }, 0);
          });
      }
      return this.formatCurrency(sum);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarInvoiceProviderContent"].getInvoiceProviderDetails(
        event.rowData.id
      );
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
