<template lang="pug"> 
  div
    b-modal#modal-prevent-closing-new-delivery-form(ok-title="Nouvel inventaire" hide-footer cancel-title="Annuler" size="lg" ref='modal-new-delivery-form' title='Nouvelle facture' @ok='handleOk' :header-class="'bg-light-primary'" :ok-disabled="!selectStorageLocation")
      template.d-block(#modal-header="{ close }")
        div.w-100
          button.absolute(type="button" aria-label="Close" class="close" @click="close()") ×
          b-row.my-0
            b-col.py-0.mb-0(cols='12' style="padding:0px !important")
              h3.text-primary Nouveau bon de sortie
      div(v-if="institutionSelected != null")
          .d-flex
            .list-buttom-custom.m-1
                div.relative.doc-buttom-custom(@click="handleSubmit(1)")
                    img.w-100(:src="getSrcIcon(1)")
            .list-buttom-custom.m-1
                div.relative.doc-buttom-custom(@click="handleSubmit(2)")
                    img.w-100(:src="getSrcIcon(2)")
            .list-buttom-custom.m-1
                div.relative.doc-buttom-custom(@click="handleSubmit(0)")
                    img.w-100(:src="getSrcIcon(0)")
          // en mode select
           .d-flex 
            label(label-for='searchStorageLocation' style='font-weight: 600;') Sur le point de stockage :
           b-row.my-0(v-if="storageLocationsList.length > 0")
            b-col.py-0(cols='12')
              b-form-group.mb-1
                label(label-for='searchStorageLocation' style='font-weight: 600;') Dans le point de stockage :
                v-select.w-100(id="selectStorageLocation" :loading="isLoadingStorageLocationsList" :clearable="false" v-model="selectStorageLocation" label="label" :options="storageLocationsList" )
                  li.border-bottom.p-1.py-50(slot="list-footer")
                    //- b-button(@click="newStorageLocation()" variant="primary" size="sm" class="w-100 d-flex justify-content-center align-items-center")
                    //-   | + Nouvelle société
                    //-   span(class="link_icon material-icons-outlined text-white m-0 ml-50" style="font-size:13px")
                    //-     | open_in_new
                  template(v-slot:no-options="")
                    template Aucune société trouvée
                  template.vbt-autcomplete-list(v-slot:option="option")
                    .item
                      span(style="font-weight:500") {{ option.label }}
                      br
                      small {{(option.addressComplement ? option.address + ' ' + option.addressComplement : option.address)}} {{ option.zipCode }} {{ option.city }}

</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import dayjs from "dayjs";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    "v-select": vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    submittedNames: [],
    selectStorageLocation: null,
    tabActive: "blank-delivery-form",
  }),
  props: {
    affairId: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingStorageLocationsList",
      "storageLocationsList",
      "isLoadingInstitutionsList",
      "institutionsList",
      "institutionSelected",
      "workspaceSelected",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
  },
  async created() {
    await this.getStorageLocations({});
  },
  methods: {
    ...mapActions(["getStorageLocations", "getDeliveryFormEmpty"]),
    formatDate,
    getSrcIcon(type) {
      switch (type) {
        case 0:
          return require("@/assets/icones/icon-doc-delivery.svg");
          break;
        case 1:
          return require("@/assets/icones/icon-doc-transfert.svg");
          break;
        case 2:
          return require("@/assets/icones/icon-doc-hand-delivery.svg");
          break;
        default:
          return require("@/assets/icones/icon-doc-hand-delivery.svg");
          break;
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit(deliveryType = 0) {
      if (deliveryType != null) {
        this.$store.commit(
          "SET_IS_GLOBAL_MESSAGE",
          "Création du bon de sortie en cours..."
        );
        this.$store.commit("SET_IS_GLOBAL_CREATING", true);
        await this.getDeliveryFormEmpty({
          deliveryType: deliveryType,
          customerId: this.companyId,
          affairId: this.affairId,
        })
          .then((deliveryForm) => {
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
            this.$router.push({
              name: "new-delivery-form",
              params: {
                id: deliveryForm.id,
                title: "Nouveau bon de sortie",
                tips: "Nouveau bon de sortie",
                routeOrigine: "delivery-forms",
              },
            });
            this.$refs["modal-new-delivery-form"].toggle(
              "#toggle-btn-new-delivery-form"
            );
          })
          .catch((error) => {
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          });
      }
    },
    toggleModal() {
      this.$refs["modal-new-delivery-form"].toggle(
        "#toggle-btn-new-delivery-form"
      );
    },
  },
};
</script>

<style>
#modal-prevent-closing-new-delivery-form .modal-header {
  padding-bottom: 0 !important;
}
</style>
