<template lang="pug">
  .h-100.d-flex.flex-column
      HeaderGrid(:name="name" :title='title' :uid="uid" userFilter="activityFilters" :dateRanger="dateRanger" :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" @setDates="setDates" :indexSearch="indexSearch")
      .d-flex.w-100.h-100(style="overflow: hidden;")
        .text-center.flex-center.w-100(v-if='isLoadingActivitiesListByCollaborator ||  !headerLoaded')
          .loading-bg-inner
              .loader
                .outer
                .middle
                .inner
          .mt-5
              br
              br
              br
              | Chargement de la liste des rapports d'activités...

        sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :editSettings='editSettings'  :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked"  @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @actionBegin="actionBegin" :archive="archive" :archiveMode="archiveMode" @deleteButtonClicked="deleteButtonClicked" @archiveButtonClicked="archiveButtonClicked" @restoreButtonClicked="restoreButtonClicked")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      title: "Activités commerciales",
      indexSearch: 0,
      name: "CompanyActivitiesList",
      uid: "companyActivities",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")),
      ],

      dateRanger: true,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un rapport d'activité",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouveau rapport d'activité",
        titleEdit: "Modifier le rapport d'activité",
        titleField: "label",
      },
      allowGrouping: true,
    };
  },
  computed: {
    ...mapGetters([
      "userCollaboratorId",
      "collaboratorsList",
      "companiesList",
      "company",
      "activityChanelTypesList",
      "activityResultTypesList",
      "activitySourceTypesList",
      "contactsList",
      "activitiesList",
      "isLoadingActivitiesList",
      "activitiesListArchived",
      "isLoadingActivitiesListArchived",
      "workspaceSelected",
      "userParametersList",
      "isLoadingActivitiesListByCollaborator",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters["activitiesListArchivedByCompany"];
      } else {
        return this.$store.getters["activitiesListByCompany"];
      }
    },
  },

  props: {
    companyId: {
      default: null,
      required: true,
    },
  },

  async created() {
    await this.getContacts({});
    await this.getCollaborators({});
    await this.getCompanies({});
    await this.getActivityChanelTypes({});
    await this.getActivityResultTypes({});
    await this.getActivitySourceTypes({});

    this.headerLoaded = false;
    let filter = this.userParametersList.find(
      (el) => el.key == "activityFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions([
      "getContacts",
      "getCollaborators",
      "getActivitiesByCompanyId",
      "getCompanies",
      "getActivityChanelTypes",
      "getActivityResultTypes",
      "getActivitySourceTypes",
      "getActivitiesArchivedByCompanyId",
      "createActivity",
      "updateActivity",
      "archiveActivities",
      "deleteActivities",
      "restoreActivities",
    ]),
    formatCurrency,
    setColumnsByObject,

    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];

        await this.getActivitiesByCompanyId({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          companyId: this.companyId,
        }).then(async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    async setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
          showInColumnChooser: false,
        },
        {
          type: "Date",
          field: "activityDate",
          headerText: "Date d'activitée",
          width: 140,
          maxWidth: 140,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          allowGrouping: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: new Date(),
          editType: "datetimepickeredit",
          format: "dd/MM/yyyy HH:mm",
          direction: "descending",
        },
        {
          type: "number",
          field: "collaboratorId",
          filterTemplate: "collaboratorFilterTemplate",
          headerText: "Réalisé par",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          foreignKeyValue: "id",
          dataSource: this.collaboratorsList,
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown", //<<FIX
            name: "dropdowncollaborator",
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "companyId",
          filterTemplate: "companyFilterTemplate",
          headerText: "Societe",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.companiesList,
          edit: {
            source: this.companiesList,
            fields: { text: "name", value: "id" },
            type: "dropdown", //<<FIX
            name: "dropdowncompany",
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "contactId",
          headerText: "Interlocuteur(trice)",
          filterTemplate: "contactFilterTemplate",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.contactsList,
            fields: {
              text: "displayLabel",
              value: "id",
              dataSource: this.contactsList,
            },
            type: "dropdowndependencies",
            name: "dropdowncontact",
            origine: "dropdowncompany",
          },
        },
        {
          type: "number",
          field: "activitySourceTypeId",
          filterTemplate: "activitySourceFilterTemplate",
          headerText: "Source",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.activitySourceTypesList,
          edit: {
            source: this.activitySourceTypesList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
            name: "dropdownsource",
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "activityChanelTypeId",
          filterTemplate: "activityChanelFilterTemplate",
          headerText: "Canal",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.activityChanelTypesList,
          edit: {
            source: this.activityChanelTypesList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
            name: "dropdownchanel",
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "activityResultTypeId",
          filterTemplate: "activityResultFilterTemplate",
          headerText: "Résultat",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.activityResultTypesList,
          edit: {
            source: this.activityResultTypesList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
            name: "dropdownresult",
          },
          // editType: "dropdownedit",
        },
        {
          type: "string",
          field: "comments",
          headerText: "Commentaire",
          minWidth: 300,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          allowGrouping: false,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          edit: { type: "textarea", name: "comments" },
          filter: { type: "Menu", operator: "contains" },
        },
        {
          type: "boolean",
          field: "isUnreachable",
          headerText: "NRP",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "boolean",
          editType: "booleanedit",
        },
        {
          type: "boolean",
          field: "hasLeavingMessage",
          headerText: "Message laissé",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "boolean",
          editType: "booleanedit",
        },
        {
          type: "date",
          field: "reminderDate",
          headerText: "Date relance",
          width: 140,
          maxWidth: 140,
          format: "dd/MM/yyyy HH:mm",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          allowGrouping: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: new Date(),
          editType: "datetimepickeredit",
        },
      ];
    },
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        await this.createActivity({
          activity: args.data,
          route: "company",
        });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
      if (args.requestType === "save" && args.action === "edit") {
        await this.updateActivity({ activity: args.data, route: "company" });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
    },
    clickAction() {
      this.$router.push({
        name: "new-activity",
        params: { routeOrigine: "directory/my-activities" },
      });
    },
    onRowClicked(event) {
      //this.$refs.overviewgrid.selectRow(event.rowIndex, false)
      // this.$refs.overviewgrid.$refs.overviewgrid.startEdit();
    },
    async archiveButtonClicked(args) {
      await this.archiveActivities({ activityIds: [args.rowData.id] });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async restoreButtonClicked(args) {
      await this.restoreActivities({
        activityIds: [args.rowData.id],
        route: "company",
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async deleteButtonClicked(args) {
      await this.deleteActivities({
        activityIds: [args.rowData.id],
        route: "company",
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getActivitiesArchivedByCompanyId({
          companyId: this.companyId,
        });
        this.archive = true;
      } else if (args.item.type == "current") {
        let filter = this.userParametersList.find(
          (el) => el.key == "activityFilters"
        );
        if (filter) {
          this.getActivitiesByCompanyId({
            startDate: JSON.parse(filter.value).dateRange.startDate,
            endDate: JSON.parse(filter.value).dateRange.endDate,
            companyId: this.companyId,
          });
        } else {
          this.getActivitiesByCompanyId({
            startDate: dayjs()
              .subtract(3, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
            companyId: this.companyId,
          });
        }
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
