<template lang="pug">
div.w-100
    .d-flex.flex-column.w-100.px-1
        .w-100.pt-1(v-for="(indicator, indexIndicator) of companyIndicatorsList" :key="indexIndicator")
            b-form-group.w-100(v-if="indicator.typeField==11")
                b-form-checkbox.custom-control-primary( :id="'companyIndicator_'+indicator.id" v-model="formCompanyIndicators['companyIndicator_'+indicator.id]") {{ indicator.label }}
            b-form-group.w-100(v-else :label-for="'companyIndicator_'+indicator.id" :label="indicator.label" v-model="formCompanyIndicators['companyIndicator_'+indicator.id]")
                ejs-numerictextbox(v-if="indicator.typeField==1 || indicator.typeField==2 || indicator.typeField==3" :id="'companyIndicator_'+indicator.id" :label="indicator.label" :value='0'  :format='indicator.typeField==2 ? "########.## €" : indicator.typeField==3 ? "### %" : null' v-model="formCompanyIndicators['companyIndicator_'+indicator.id]")
                ejs-textbox(v-else-if="indicator.typeField==4 || indicator.typeField==5" :id="'companyIndicator_'+indicator.id" :label="indicator.label" :multiple="indicator.typeField==5 ? true : false" v-model="formCompanyIndicators['companyIndicator_'+indicator.id]")
                //ejs-richtexteditor(v-else-if="indicator.typeField==6" :id="'companyIndicator_'+indicator.id" :toolbarSettings="toolbarSettings" height="300px" :label="indicator.label" v-model="formCompanyIndicators['companyIndicator_'+indicator.id]")
                ejs-datepicker(v-else-if="indicator.typeField==7" :id="'companyIndicator_'+indicator.id" :format="'dd/MM/yyyy'" locale='fr' placeholder="JJ/MM/AAAA" :label="indicator.label" v-model="formCompanyIndicators['companyIndicator_'+indicator.id]")
                ejs-datetimepicker(v-else-if="indicator.typeField==8" :id="'companyIndicator_'+indicator.id" locale='fr'  :firstDayOfWeek="firstDayOfWeek" placeholder="JJ/MM/AAAA HH:MM" :label="indicator.label" v-model="formCompanyIndicators['companyIndicator_'+indicator.id]")
                ejs-dropdownlist(v-else-if="indicator.typeField==9" :id="'companyIndicator_'+indicator.id" placeholder='Selectionnez une valeur' popupHeight='300px' sortOrder="None" filterType='Contains' :allowFiltering='true' :dataSource='indicator.options ? indicator.options.split(";") : []' :label="indicator.label" v-model="formCompanyIndicators['companyIndicator_'+indicator.id]")
                ejs-multiselect(v-else-if="indicator.typeField==10" :id="'companyIndicator_'+indicator.id" placeholder='Selectionnez une valeur' popupHeight='300px' sortOrder="None" filterType='Contains' :allowFiltering='true' :dataSource='indicator.options ? indicator.options.split(";") : []' :label="indicator.label" v-model="formCompanyIndicators['companyIndicator_'+indicator.id]")
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    companyId: {
      default: null,
      required: true,
    },
    companyForm: {},
  },
  data() {
    return {
      firstDayOfWeek: 1,
      showCharCount: true,
      quickToolbarSettings: {
        table: [
          "TableHeader",
          "TableRows",
          "TableColumns",
          "TableCell",
          "-",
          "BackgroundColor",
          "TableRemove",
          "TableCellVerticalAlign",
          "Styles",
        ],
      },
      toolbarSettings: {
        items: [
          "Bold",
          "Italic",
          "Underline",
          "StrikeThrough",
          "FontName",
          "FontSize",
          "FontColor",
          "BackgroundColor",
          "LowerCase",
          "UpperCase",
          "SuperScript",
          "SubScript",
          "EmojiPicker",
          "|",
          "Formats",
          "Alignments",
          "NumberFormatList",
          "BulletFormatList",
          "Outdent",
          "Indent",
          "|",
          "CreateTable",
          "CreateLink",
          "|",
          "ClearFormat",
          "Print",
          "SourceCode",
          "FullScreen",
          "|",
          "Undo",
          "Redo",
        ],
      },
      formCompanyIndicators: {},
    };
  },
  computed: {
    ...mapGetters(["companyIndicatorsList"]),
  },
  async created() {
    if (
      this.companyForm.companyIndicators &&
      this.companyForm.companyIndicators.length > 0
    ) {
      for (
        let index = 0;
        index < this.companyForm.companyIndicators.length;
        index++
      ) {
        const companyIndicator = this.companyForm.companyIndicators[index];
        this.formCompanyIndicators[
          "companyIndicator_" + companyIndicator.companyIndicator.id
        ] = companyIndicator.value;
      }
    }
  },
  methods: {
    ...mapActions(["updateCompanyCompanyIndicator"]),
    async saveCompanyIndicator() {
      let companyIndicators = {};
      for (const cle in this.formCompanyIndicators) {
        if (cle.startsWith("companyIndicator_")) {
          const companyIndicatorId = parseInt(cle.substring(17));
          companyIndicators[companyIndicatorId] =
            this.formCompanyIndicators[cle];
        }
      }
      if (companyIndicators !== {}) {
        await this.updateCompanyCompanyIndicator({
          companyId: this.companyId,
          companyIndicators: companyIndicators,
        });
      }
    },
  },
};
</script>
<style>
@import "~@syncfusion/ej2-vue-richtexteditor/styles/bootstrap5.css";
</style>
