<template>
  <div class="h-100">
    <div class="justify-content-center h-100" v-if="isLoading">
      <div class="content-loader-center m-0 h-100">
        <div class="text-center flex-center">
          <div class="loading-bg-inner">
            <div class="loader">
              <div class="outer"></div>
              <div class="middle"></div>
              <div class="inner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="gedViewer" class="h-100" v-else>
      <div class="d-flex flex-column">
        <div
          id="nav-sticky-horizontal"
          class="align-items-center w-100 d-flex justify-content-between p-1"
        >
          <h2 class="content-header-title m-0 pr-1 mr-md-1">Pièces/GED</h2>
          <div class="content-header-search mr-1">
            <ul class="breadcrumb d-flex align-items-center">
              <li
                v-show="breadcrumbFolderList.length > 1"
                v-for="(breadcrumb, index) in breadcrumbFolderList"
                :key="index"
                class="inline-flex items-center"
                @click="openFolder(breadcrumb, $event)"
              >
                <span class="name">{{ breadcrumb.name }}</span>
                <span class="material-icons-outlined">
                  {{
                    index + 1 == breadcrumbFolderList.length
                      ? "arrow_drop_down"
                      : "chevron_right"
                  }}
                </span>
              </li>
            </ul>
            <!-- <b-input-group style="flex: 1">
              <div class="position-relative w-100 d-flex align-items-center">
                <b-button
                  variant="transparent"
                  class="p-0 position-absolute btn-gray br border-right-0"
                  style="left: 0.5rem"
                >
                  <span class="material-icons-outlined text-secondary"
                    >search</span
                  >
                </b-button>
                <b-form-input
                  id="search"
                  v-model="searchDocument"
                  placeholder="Rechercher un fichier"
                  class="w-full pl-4"
                  style="
                    border-top-right-radius: 0.357rem;
                    border-bottom-right-radius: 0.357rem;
                  "
                >
                </b-form-input>
              </div>
            </b-input-group> -->
          </div>
          <div class="content-header-actions" style="height: 32px">
            <b-button-group class="mr-1 h-100">
              <b-button
                v-if="
                  this.selectedItems.length > 0 &&
                  this.selectedItems.filter((elem) => elem.type == 'file')
                    .length > 0
                "
                class="p-50 d-flex align-items-center h-100"
                variant="outline-primary"
                style="min-height: 32px"
                @click="downloadFileMultiple()"
              >
                <span
                  class="material-icons text-primary"
                  style="font-size: 16px"
                  >file_download</span
                >
                Télécharger&nbsp;<span v-if="this.selectedItems.length > 1"
                  >({{ this.selectedItems.length }})</span
                >
              </b-button>
              <b-button
                v-if="this.selectedItems.length > 0"
                class="p-50 d-flex align-items-center h-100"
                variant="outline-danger"
                style="min-height: 32px"
                @click="deleteMultiple()"
              >
                <span class="material-icons text-danger" style="font-size: 16px"
                  >delete</span
                ><span v-if="this.selectedItems.length > 1"
                  >({{ this.selectedItems.length }})</span
                >
              </b-button>
            </b-button-group>
            <!-- <b-button class="px-1 mr-1" variant="primary" @click="popupEditFolder(0)"> 
            + Nouveau
          </b-button> -->
            <b-button-group class="h-100">
              <b-button
                variant="outline-primary"
                class="my-0 py-0 px-50 h-100"
                :class="viewList ? 'bg-light-primary' : ''"
                @click="showList()"
              >
                <span
                  style="font-size: 16px"
                  class="material-icons-outlined m-0 p-0"
                  >{{ viewList ? "grid_view" : "format_list_bulleted" }}</span
                >
              </b-button>
              <b-dropdown
                class="bg-outline-primary btn-group-icon h-100"
                variant="outline-primary"
              >
                <template #button-content>
                  <span
                    class="material-icons-outlined m-0 p-0"
                    style="font-size: 16px"
                    >settings</span
                  >
                </template>
                <b-dropdown-item class="w-full" @click="sort('name')"
                  >Trier par Nom</b-dropdown-item
                >
                <b-dropdown-item class="w-full" @click="sort('type')"
                  >Trier par Type</b-dropdown-item
                >
              </b-dropdown>
            </b-button-group>
          </div>
        </div>
      </div>
      <div
        v-if="isLoadingFolder || isLoadingFoldersList"
        class="justify-content-center h-100"
        style="flex: 1"
      >
        <div class="content-loader-center m-0 h-100">
          <div class="text-center flex-center">
            <div class="loading-bg-inner">
              <div class="loader">
                <div class="outer"></div>
                <div class="middle"></div>
                <div class="inner"></div>
              </div>
            </div>
            <div class="mt-5">
              <br /><br /><br />
              Chargement du dossier en cours...
            </div>
          </div>
        </div>
      </div>
      <b-row
        v-else
        class="m-0"
        style="height: calc(100% - 61px); overflow-y: auto"
      >
        <div class="finder d-flex flex-column h-100 w-100 p-1">
          <div class="finder-list" v-if="viewList">
            <table class="table table-striped w-100" v-if="!isLoadingFolder">
              <thead class="thead">
                <tr>
                  <th
                    scope="col"
                    v-for="field in fields"
                    :key="field.key"
                    :class="[
                      field.tdClass,
                      sortBy === field.key ? sortDirection : '',
                    ]"
                    :style="field.thStyle"
                    @click="sort(field.key)"
                  >
                    {{ field.label }}
                  </th>
                </tr>
              </thead>
              <!-- <drag-select attribute="itemNumber" v-slot="{ selected }" @change="selectedItems = $event" style="display: contents;"> -->
              <draggable
                v-model="items"
                tag="tbody"
                ghost-class="ghost"
                :move="onMove"
                @end="endDrag"
              >
                <tr
                  v-for="(item, index) in items"
                  :key="item.id"
                  :itemNumber="item.id"
                  :class="{ 'row-selected': selectedItems.includes(item) }"
                >
                  <td
                    v-for="field in fields"
                    :key="field.key"
                    :class="field.tdClass"
                    :style="field.tdStyle"
                    @click.stop="onRowClicked($event, item, index)"
                  >
                    <div
                      v-if="field.key == 'type'"
                      class="cell-type"
                      :style="bgImageSmall(item.fullPath)"
                    >
                      <span
                        v-if="item.folderApplication"
                        class="folder-application material-icons"
                        >lock</span
                      >
                      <span
                        v-if="!item.folderApplication"
                        class="fake-checkbox"
                        @click.stop="selectItem(item)"
                      >
                        <span class="checked">
                          <span
                            class="material-icons text-white"
                            v-show="selectedItems.includes(item)"
                            >done</span
                          >
                        </span>
                      </span>
                    </div>
                    <div
                      v-else-if="field.key == 'updatedAt'"
                      style="text-transform: capitalize"
                    >
                      {{ formatDate(item.updatedAt) }}
                    </div>
                    <div
                      v-else-if="field.key == 'fileSize'"
                      style="text-transform: capitalize"
                    >
                      <span v-if="item.type == 'file'">{{
                        sizeFormat(item.fileSize)
                      }}</span>
                    </div>
                    <div v-else-if="field.key == 'name'">
                      <b-input-group
                        v-if="itemEdit == item.id"
                        size="small"
                        class="m-0 p-0"
                      >
                        <b-form-input
                          class="p-0 px-50"
                          :ref="'input-item-' + item.id"
                          v-model="item.name"
                          style="max-height: 30px; height: 30px"
                          v-on:keyup.enter="renameFile(item)"
                          @focus="$event.target.select()"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            class="p-0 px-1"
                            size="sm"
                            text="Button"
                            variant="primary"
                            style="max-height: 30px; height: 30px"
                            @click.stop="renameFile(item)"
                            >Renommer</b-button
                          >
                          <b-button
                            class="p-0 px-1"
                            size="sm"
                            text="Button"
                            variant="outline-primary"
                            style="max-height: 30px; height: 30px"
                            @click.stop="editItem(0)"
                            >X</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                      <div v-else-if="item.folderApplication" class="m-0 p-0">
                        {{ item.name }}
                      </div>
                      <div
                        v-else
                        class="m-0 p-0"
                        style="
                          width: fit-content;
                          -webkit-user-select: none; /* Safari */
                          -ms-user-select: none; /* IE 10 and IE 11 */
                          user-select: none; /* Standard syntax */
                        "
                        @click.stop="editItemDbl(item.id)"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                    <div v-else>
                      {{ item[field.key] }}
                    </div>
                  </td>
                </tr>
              </draggable>
              <!-- </drag-select> -->
            </table>
          </div>
          <div
            class="finder-content"
            v-if="
              !viewList && folder.subFolders && folder.subFolders.length > 0
            "
          >
            <div style="padding-top: 10px; padding-bottom: 12px">
              <div
                @click.right.stop
                @contextmenu.prevent="
                  !itemFolder.folderApplication
                    ? $refs.menuFolder[indexFolder].open($event)
                    : false
                "
                v-for="(itemFolder, indexFolder) in folder.subFolders"
                :key="indexFolder"
                style="width: 100%"
                @dblclick="openFolder(itemFolder, $event)"
                v-b-tooltip.hover.top="itemFolder.name"
              >
                <div class="content-div-folder">
                  <div class="ged-grid-item">
                    <span class="material-icons">folder</span>
                    <p class="name">
                      {{ itemFolder.name }}
                    </p>
                    <span
                      v-if="itemFolder.folderApplication"
                      class="folder-application material-icons"
                      >lock</span
                    >
                  </div>
                </div>
                <vue-context ref="menuFolder" class="p-0">
                  <template>
                    <li class="contextChoice p-0 text-sm">
                      <a
                        @click.prevent="popupEditFolder(itemFolder.id)"
                        class="text-sm"
                      >
                        <feather-icon icon="Edit3Icon" svgClasses="w-3 h-3" />
                        Renommer
                      </a>
                    </li>
                    <!-- <li class="contextChoice p-0 text-sm">
                        <a @click.prevent="removeFolder(itemFolder.id)" class="text-sm">
                        <feather-icon icon="TrashIcon" svgClasses="w-3 h-3" />
                        Supprimer
                        </a>
                    </li> -->
                    <!-- <li class="contextChoice p-0 text-sm">
                        <a class="text-sm" @click.prevent="moveFile(itemFolder)">
                        <feather-icon
                            icon="CornerDownRightIcon"
                            svgClasses="w-3 h-3"
                        />
                        Déplacer vers...
                        </a>
                    </li> -->
                  </template>
                </vue-context>
              </div>
            </div>
          </div>
          <div
            class="finder-content"
            v-if="!viewList && folder.files && folder.files.length > 0"
          >
            <div style="width: 100%; padding-top: 10px">
              <div
                @click.right.stop
                @contextmenu.prevent="$refs.file[index].open($event)"
                v-for="(item, index) in folder.files"
                :key="index"
                @click="onFileClicked(item, index)"
              >
                <div class="ged-grid-item">
                  <div class="file-hover" v-b-tooltip.hover.top="item.name">
                    <div
                      class="file-img d-flex justify-content-center m-0 p-2"
                      @click.stop="showFile(item.id)"
                    >
                      <div
                        class="m-0 p-0 enableBlur"
                        style="max-height: 190px"
                        :style="bgImage(item.fullPath)"
                      ></div>
                    </div>
                    <div
                      class="file-text m-0 px-2 text-center"
                      @click.stop="showFile(item.id)"
                    >
                      <span>{{ item.name }} - {{ item.hover }}</span>
                    </div>

                    <vue-context ref="file" class="p-0">
                      <template>
                        <li class="contextChoice p-0 text-sm">
                          <a
                            @click.prevent="popupEditFile(item.id)"
                            class="text-sm"
                          >
                            <feather-icon
                              icon="Edit3Icon"
                              svgClasses="w-3 h-3"
                            />
                            Renommer
                          </a>
                        </li>
                        <li class="contextChoice p-0 text-sm">
                          <a @click.prevent="moveFile(item)" class="text-sm">
                            <feather-icon
                              icon="CornerDownRightIcon"
                              svgClasses="w-3 h-3"
                            />
                            Déplacer vers...
                          </a>
                        </li>
                      </template>
                    </vue-context>
                    <div class="file-tools disableBlur">
                      <div
                        class="
                          d-flex
                          justify-content-center
                          align-items-center
                          w-100
                          content-btn
                          mb-2
                        "
                      >
                        <b-button
                          variant="primary"
                          pill
                          class="d-flex p-0 mr-1 rounded"
                          @click="downloadFile(item.fullPath, item.name)"
                        >
                          <span
                            class="material-icons text-white"
                            style="font-size: 20px"
                            >file_download</span
                          >
                        </b-button>
                        <b-button
                          variant="danger"
                          pill
                          class="d-flex p-0 rounded"
                          @click="deleteFileForm(item)"
                        >
                          <span
                            class="material-icons text-white"
                            style="font-size: 20px"
                            >delete</span
                          >
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100" style="flex: 1">
            <div class="inputDropZoneSidebar"></div>
            <vue-dropzone
              ref="imgDropZone"
              id="customdropzone"
              class="border-primary w-100"
              :options="dropzoneOptions"
              @vdropzone-file-added="uploadFileAdded"
            ></vue-dropzone>
          </div>
        </div>
      </b-row>
      <LightBox
        v-if="folder.files && folder.files.length > 0"
        ref="lightbox"
        :showCaption="true"
        :showLightBox="false"
        :media="folder.files"
      ></LightBox>
      <popup-edit-file
        ref="popupEditFile"
        :fromModule="fromModule"
        :parentId="folder && folder.id ? folder.id : affair.folderId"
      ></popup-edit-file>
      <popup-edit-folder
        ref="popupEditFolder"
        :fromModule="fromModule"
        :fromId="affair.Id"
        :parentId="folder && folder.id ? folder.id : affair.folderId"
      ></popup-edit-folder>
      <ejs-sidebar
        class="default-sidebar items-no-padding"
        ref="sidebar"
        :type="type"
        :position="position"
        :showBackdrop="showBackdrop"
        :enablePersistence="enablePersistence"
        :closeOnDocumentClick="closeOnDocumentClick"
        :isOpen="isOpen"
      >
        <sidebar-file-content
          ref="sidebarFileContent"
          @closeSidebar="closeSidebar"
        />
      </ejs-sidebar>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import DragSelect from "drag-select-vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { VueContext } from "vue-context";
import "vue-context/dist/css/vue-context.css";
import LightBox from "vue-it-bigger";
import { mapGetters, mapActions } from "vuex";
import { BTable } from "bootstrap-vue";
import PopupEditFolder from "@/components/ged/PopupEditFolder";
import PopupEditFile from "@/components/ged/PopupEditFile";
import SidebarFileContent from "@/components/ged/SidebarFileContent";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";
import { checkImageExists } from "@/types/api-orisis/library/GlobalOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  props: {
    fromModule: {
      default: null,
      require: false,
    },
    parentFolder: {
      default: null,
      require: true,
    },
    origine: {
      default: null,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      selectedItems: [],
      sortDirection: "desc",
      sortBy: "type",
      delay: 400,
      clicks: 0,
      clicksEdit: 0,
      timer: null,
      timerEdit: null,
      itemEdit: 0,
      isLoading: true,
      searchDocument: "",
      viewList: false,
      relatedElement: {},
      draggedElement: {},
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          thStyle: { width: "30px" },
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "Dernière modification",
          sortable: true,
          tdClass: "text-center",
          thStyle: { width: "200px" },
        },
        {
          key: "fileSize",
          label: "Poids",
          sortable: true,
          tdClass: "text-right",
          thStyle: { width: "100px" },
        },
      ],
      draggingRow: null,
      draggingRowIndex: null,
      draggingColumn: null,
      draggingColumnIndex: null,

      breadcrumbFolderList: [],
      dropzoneOptions: {
        hiddenInputContainer: ".inputDropZoneSidebar",
        autoQueue: false,
        url: "ORISIS",
        maxFilesize: 10000000,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        dictDefaultMessage: `<i class='fa fa-cloud-upload'></i><p class='text-default'>Faites glisser vos documents ou cliquez ici</p>`,
        manuallyAddFile: true,
        autoProcessQueue: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "affair",
      "foldersList",
      "institutionSelected",
      "isLoadingFolder",
      "isLoadingFoldersList",
    ]),
    items: {
      get() {
        let items = [];
        if (
          this.$store.getters.folder &&
          this.$store.getters.folder?.subFolders?.length > 0
        ) {
          items = this.$store.getters.folder.subFolders.map((elem) => {
            return {
              id: elem.id,
              type: "folder",
              name: elem.name,
              fullPath: "",
              fileSize: 0,
              updatedAt: elem.updatedAt,
              folderApplication: elem.folderApplication,
            };
          });
        }
        if (
          this.$store.getters.folder &&
          this.$store.getters.folder?.files?.length > 0
        ) {
          items = items.concat(
            this.$store.getters.folder.files.map((elem) => {
              return {
                id: elem.id,
                type: "file",
                name: elem.name,
                fullPath: elem.fullPath,
                fileSize: elem.fileSize,
                updatedAt: elem.updatedAt,
                folderApplication: elem.folderApplication,
              };
            })
          );
        }
        return items
          .filter((elem) =>
            elem.name.toLowerCase().includes(this.searchDocument.toLowerCase())
          )
          .sort((p1, p2) => {
            let modifier = 1;
            if (this.sortDirection === "desc") modifier = -1;
            if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
            if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
            return 0;
          });
      },
      set(value) {
        return value;
      },
    },
    folder: {
      get() {
        return this.$store.getters.folder;
      },
      set(value) {
        return this.$store.commit("SET_FOLDER", value);
      },
    },
  },
  components: {
    BTable,
    vueDropzone: vue2Dropzone,
    PopupEditFolder,
    PopupEditFile,
    LightBox,
    SidebarFileContent,
    VueContext,
    draggable,
    DragSelect,
    "ejs-sidebar": SidebarComponent,
  },
  beforeMount() {
    this.getUserParameterByKey({ key: "defaultViewListGed" }).then((res) => {
      if (res) {
        this.viewList = JSON.parse(res.value).viewList;
      }
    });
  },
  async created() {
    this.isLoading = true;
    if (localStorage.getItem("defaultViewListGed")) {
      this.viewList = localStorage.getItem("defaultViewListGed");
    }
    await this.getFolderById({
      folderId: this.parentFolder,
    });
    await this.getFoldersTreeById({
      folderId: this.parentFolder,
    });
    this.breadcrumbFolder();
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions([
      "updateFile",
      "getFoldersTreeById",
      "getFolderById",
      "createFile",
      "deleteFiles",
      "deleteFolders",
      "updateFileFolder",
      "updateFolder",
      "uploadFileAffair",
      "getAffairById",
      "createOrUpdateUserParameter",
      "getUserParameterByKey",
    ]),
    checkImageExists,
    formatDate,
    deleteMultiple() {
      for (let index = 0; index < this.selectedItems.length; index++) {
        const element = this.selectedItems[index];
        if (element.type == "file") {
          // On gère la suppression du fichier
          this.deleteFiles({
            fileIds: [element.id],
          });
        } else {
          // On gèrera la suppression du dossier
          this.deleteFolders({
            folderIds: [element.id],
          });
        }
      }
      this.$nextTick(() => {
        this.selectedItems = [];
        this.closeSidebar();
      });
    },

    downloadFileMultiple() {
      for (let index = 0; index < this.selectedItems.length; index++) {
        const element = this.selectedItems[index];
        this.downloadFile(element.fullPath, element.name);
      }
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    downloadFile(url, label) {
      let mineType = "application/pdf";
      const urlExtension = this.get_url_extension(url);
      switch (urlExtension) {
        case "aac":
          mineType = "audio/aac";
          break;
        case "abw":
          mineType = "application/x-abiword";
          break;
        case "arc":
          mineType = "application/octet-stream";
          break;
        case "avi":
          mineType = "video/x-msvideo";
          break;
        case "azw":
          mineType = "application/vnd.amazon.ebook";
          break;
        case "bin":
          mineType = "application/octet-stream";
          break;
        case "bmp":
          mineType = "image/bmp";
          break;
        case "bz":
          mineType = "application/x-bzip";
          break;
        case "bz2":
          mineType = "application/x-bzip2";
          break;
        case "csh":
          mineType = "application/x-csh";
          break;
        case "css":
          mineType = "text/css";
          break;
        case "csv":
          mineType = "text/csv";
          break;
        case "doc":
          mineType = "application/msword";
          break;
        case "docx":
          mineType =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case "eot":
          mineType = "application/vnd.ms-fontobject";
          break;
        case "epub":
          mineType = "application/epub+zip";
          break;
        case "gif":
          mineType = "image/gif";
          break;
        case "htm":
          mineType = "text/html";
          break;
        case "html":
          mineType = "text/html";
          break;
        case "ico":
          mineType = "image/x-icon";
          break;
        case "ics":
          mineType = "text/calendar";
          break;
        case "har":
          mineType = "application/java-archive";
          break;
        case "jpeg":
          mineType = "image/jpeg";
          break;
        case "jpg":
          mineType = "image/jpeg";
          break;
        case "js":
          mineType = "application/javascript";
          break;
        case "json":
          mineType = "application/json";
          break;
        case "mid":
          mineType = "audio/midi";
          break;
        case "midi":
          mineType = "audio/midi";
          break;
        case "mpeg":
          mineType = "video/mpeg";
          break;
        case "mpkg":
          mineType = "application/vnd.apple.installer+xml";
          break;
        case "opd":
          mineType = "application/vnd.oasis.opendocument.presentation";
          break;
        case "ods":
          mineType = "application/vnd.oasis.opendocument.spreadsheet";
          break;
        case "odt":
          mineType = "application/vnd.oasis.opendocument.text";
          break;
        case "ogg":
          mineType = "audio/ogg";
          break;
        case "ogv":
          mineType = "video/ogg";
          break;
        case "ogx":
          mineType = "application/ogg";
          break;
        case "otf":
          mineType = "font/otf";
          break;
        case "png":
          mineType = "image/png";
          break;
        case "pdf":
          mineType = "application/pdf";
          break;
        case "ppt":
          mineType = "application/vnd.ms-powerpoint";
          break;
        case "pptx":
          mineType =
            "application/vnd.openxmlformats-officedocument.presentationml.presentation";
          break;
        case "rar":
          mineType = "application/x-rar-compressed";
          break;
        case "rtf":
          mineType = "application/rtf";
          break;
        case "sh":
          mineType = "application/x-sh";
          break;
        case "svg":
          mineType = "image/svg+xml";
          break;
        case "swf":
          mineType = "application/x-shockwave-flash";
          break;
        case "tar":
          mineType = "application/x-tar";
          break;
        case "tiff":
          mineType = "image/tiff";
          break;
        case "tif":
          mineType = "image/tiff";
          break;
        case "ts":
          mineType = "application/typescript";
          break;
        case "ttf":
          mineType = "font/ttf";
          break;
        case "vsd":
          mineType = "application/vnd.visio";
          break;
        case "wav":
          mineType = "audio/x-wav";
          break;
        case "weba":
          mineType = "audio/webm";
          break;
        case "webm":
          mineType = "video/webm";
          break;
        case "webp":
          mineType = "	image/webp";
          break;
        case "woff":
          mineType = "font/woff";
          break;
        case "woff2":
          mineType = "font/woff2";
          break;
        case "xhtml":
          mineType = "application/xhtml+xml";
          break;
        case "xls":
          mineType = "application/vnd.ms-excel";
          break;
        case "xlsx":
          mineType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
        case "xml":
          mineType = "application/xml";
          break;
        case "xul":
          mineType = "application/vnd.mozilla.xul+xml";
          break;
        case "zip":
          mineType = "application/zip";
          break;
        case "3gp":
          mineType = "video/3gpp";
          break;
        case "3g2":
          mineType = "video/3gpp";
          break;
        case "7z":
          mineType = "application/x-7z-compressed";
          break;
        default:
          mineType = "text/plain";
      }

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: mineType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },

    onMove({ relatedContext, draggedContext }) {
      this.relatedElement = relatedContext.element;
      this.draggedElement = draggedContext.element;
      if (this.draggedElement.folderApplication == true) {
        return false;
      }
    },

    endDrag(e) {
      this.$nextTick(() => {
        if (
          e.item._underlying_vm_.type == "file" &&
          this.relatedElement.type == "folder"
        ) {
          let fileId = e.item._underlying_vm_.id;
          let fileFolders = [];
          fileFolders.push(this.relatedElement.id);
          this.updateFileFolder({ fileId: fileId, fileFolders: fileFolders });
        } else if (
          e.item._underlying_vm_.type == "folder" &&
          this.relatedElement.type == "folder"
        ) {
          // Déplacer un dossier dans un dossier
          let itemFolder = structuredClone({
            id: e.item._underlying_vm_.id,
            name: e.item._underlying_vm_.name,
            folderApplication: false,
            parentId: this.relatedElement.id,
          });
          this.updateFolder({
            folder: itemFolder,
          });
        }
      });
    },

    sort: function (s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
    },

    selectItem(item) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(
          this.selectedItems.findIndex((elem) => elem == item),
          1
        );
      }
    },

    showFile(id) {
      this.$refs["sidebarFileContent"].getFileDetails(id);
      this.$refs.sidebar.toggle();
    },

    showList() {
      this.viewList = !this.viewList;
      this.createOrUpdateUserParameter({
        label: "Options de la GED",
        key: "defaultViewListGed",
        value: JSON.stringify({ viewList: this.viewList }),
      });
      // localStorage.setItem("defaultViewListGed", this.viewList);
    },

    editItemDbl(id) {
      this.clicksEdit++;
      if (this.clicksEdit === 1) {
        this.showFile(id);
        this.itemEdit = 0;
        this.timerEdit = setTimeout(() => {
          this.clicksEdit = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timerEdit);
        this.clicksEdit = 0;
        this.itemEdit = id;
        this.selectedItems = [];
        if (id > 0) {
          this.$nextTick(() => {
            this.$refs["input-item-" + id][0].focus();
          });
        }
      }
    },

    editItem(id) {
      this.itemEdit = id;
      this.selectedItems = [];
      if (id > 0) {
        this.$refs["input-item-" + id][0].focus();
      }
    },

    renameFile(item) {
      if (item.type == "file") {
        this.updateFile({ file: item });
      } else {
        let itemFolder = structuredClone({
          id: item.id,
          name: item.name,
          folderApplication: false,
          parentId:
            this.folder && this.folder.id
              ? this.folder.id
              : this.affair.folderId,
        });
        this.updateFolder({
          folder: itemFolder,
        });
      }
      this.editItem(0);
    },

    onFileClicked(item, index) {
      this.itemEdit = 0;
      this.timer = setTimeout(() => {
        this.clicks = 0;
        if (!item.folderApplication) {
          this.itemEdit = 0;
          this.showFile(item.id);
        }
      }, this.delay);
    },

    onRowClicked(event, item, index) {
      this.isOpen = true;
      this.itemEdit = 0;
      if (this.clicksEdit == 0) {
        // if(!event.shiftKey){
        // this.selectedItems=[]
        // this.selectedItems.push(item);
        this.clicks++;
        if (this.clicks === 1) {
          if (!item.folderApplication) {
            // if(this.selectedItems.length>0){
            //   // On gère la sélection multiple
            //   if(!this.selectedItems.includes(item)){
            //     this.selectedItems.push(item)
            //   }else{
            //     this.selectedItems.splice(this.selectedItems.findIndex(elem => elem ==item), 1);
            //   }
            // }else{
            //   this.selectedItems=[]
            //   this.selectedItems.push(item);
            // }
          }

          this.timer = setTimeout(() => {
            this.clicks = 0;
            if (!item.folderApplication) {
              if (this.selectedItems.length < 2) {
                if (item.type == "file") {
                  this.itemEdit = 0;
                  this.showFile(item.id);
                } else if (item.type == "folder") {
                  // this.openFolder(item)
                }
              }
            }
          }, this.delay);
        } else {
          clearTimeout(this.timer);
          this.clicks = 0;
          this.itemEdit = item.id;
          if (item.type == "file") {
            if (item.id > 0) {
              this.$nextTick(() => {
                this.$refs["input-item-" + item.id][0].focus();
              });
            }
          } else if (item.type == "folder") {
            this.openFolder(item);
          }
        }
        // }
      }
    },

    // Gestion des Fichiers
    async uploadFileAdded(upload) {
      try {
        let file = upload;
        let formData = new FormData();
        formData.append("file", file);
        await this.uploadFileAffair({
          affairId: this.affair.id,
          file: formData,
        }).then(async (response) => {
          this.$refs.imgDropZone.removeFile(upload);
          if (this.parentFolder) {
            await this.getFolderById({
              folderId: this.parentFolder,
            });
            await this.getFoldersTreeById({
              folderId: this.parentFolder,
            });
          } else {
            await this.getAffairById({ affairId: this.affair.id }).then(
              async () => {
                await this.getFolderById({
                  folderId: this.affair.folderId,
                });
                await this.getFoldersTreeById({
                  folderId: this.affair.folderId,
                });
              }
            );
          }
        });
      } catch (error) {
        console.error(error);
      }
    },

    recup_extension(url) {
      if (url != "") {
        // si le champ fichier n'est pas vide
        url.replace();
        let nbchar = url.length; // on compte le nombre de caractere que compose ce chemin
        let extension = url.substring(nbchar - 4, nbchar); // on récupere les 4 derniers caracteres
        extension = extension.toLowerCase(); //on uniforme les caracteres en minuscules au cas ou cela aurait été écris en majuscule...
        return extension; // on renvoi l'extension vers la fonction appelante
      }
    },

    bgImage(url) {
      if (url !== "") {
        let typeFile = this.recup_extension(url).replace(".", "");
        try {
          import("@/assets/icones/" + typeFile + "-color.svg");

          return (
            "background-image:url(" +
            require("@/assets/icones/" + typeFile + "-color.svg") +
            "); background-size: auto 100%; background-position:center; width: 100%; height: 150px;"
          );
        } catch (error) {
          return (
            "background-image:url(" +
            require("@/assets/icones/unknow-color.svg") +
            "); background-size: auto 100%; background-position:center; width: 100%; height: 150px;"
          );
        }
      } else {
        return (
          "background-image:url(" +
          require("@/assets/icones/unknow-color.svg") +
          "); background-size: auto 100%; background-position:center; width: 100%; height: 150px;"
        );
      }
    },

    bgImageSmall(url) {
      if (url !== "") {
        let typeFile = this.recup_extension(url).replace(".", "");
        try {
          import("@/assets/icones/" + typeFile + "-color.svg");

          return (
            "background-image:url(" +
            require("@/assets/icones/" + typeFile + "-color.svg") +
            "); background-size: auto 100%; background-position:center; width: 30px; height: 30px; position: relative"
          );
        } catch (error) {
          return (
            "background-image:url(" +
            require("@/assets/icones/unknow-color.svg") +
            "); background-size: auto 100%; background-position:center; width: 30px; height: 30px; position: relative"
          );
        }
      } else {
        return (
          "background-image:url(" +
          require("@/assets/icones/unknow-color.svg") +
          "); background-size: auto 100%; background-position:center; width: 30px; height: 30px; position: relative"
        );
      }
    },

    bgFolderImg(img) {
      return (
        "background-image:url(" +
        img.replace("Ged\\", "Ged/") +
        "); background-position: center; background-size: cover;height: 30px;width:30px; background-repeat: no-repeat; "
      );
    },

    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },

    // Gestion des Dossiers
    async breadcrumbFolder() {
      let _this = this;
      let list = [];
      function traverse(node, path) {
        if (!path) path = [];
        if (node.name) {
          let folderName =
            node.name.toLowerCase() ==
            _this.institutionSelected.uniqueIdentifier.toLowerCase()
              ? _this.institutionSelected.name
              : node.name;
          let data = { name: folderName, id: node.id };
          path.push(data);
        }
        list.push(path);
        if (node.subFolders) {
          node.subFolders.forEach(function (item) {
            traverse(item, path.slice());
          });
        }
      }
      traverse(this.foldersList, []);
      this.breadcrumbFolderList = list.find((elem) =>
        elem.find((elem) => elem.id == this.folder.id)
      );
    },

    popupEditFile(id) {
      this.$bvModal.show("modal-edit-file");
      this.$nextTick(() => {
        this.$refs["popupEditFile"].popupEditFileEvent(id);
      });
      return false;
    },

    popupEditFolder(id) {
      this.$bvModal.show("modal-edit-folder");
      this.$nextTick(() => {
        this.$refs["popupEditFolder"].popupEditFolderEvent(id);
      });
      return false;
    },

    async openFolder(element, event) {
      this.editItem(0);
      await this.getFoldersTreeById({
        folderId: this.parentFolder,
      });
      await this.breadcrumbFolder();
      await this.getFolderById({
        folderId: element.id,
      });
      this.breadcrumbFolder();
    },

    deleteFileForm(item) {
      this.deleteFiles({
        fileIds: [item.id],
      });
      this.editItem(0);
    },

    setItemFileHover(index) {
      this.folder.files[index].hover = true;
    },

    sizeFormat(number) {
      let size = Math.ceil(number / 1000);
      if (size > 1000) {
        return (size / 1000).toFixed(2).replace(",", ".") + " Mo";
      } else {
        return size + " Ko";
      }
    },

    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
<style lang="scss">
.vue-dropzone {
  border: dashed 2px #d8d6de !important;
  border-radius: 0.5rem !important;
  background: transparent !important;
  height: 100%;
}

#gedViewer {
  display: flex;
  flex-direction: column;
  .dropzone.dz-clickable {
    // cursor: pointer;
    // position: absolute;
    // height: 100%;
    // margin: 0px 10px;
    // width: calc(100% - 20px);
  }
  .finder {
    margin-bottom: 1rem;
    gap: 10px;
    .finder-title {
      display: flex;
      justify-content: space-between;
    }
    .finder-list {
      .table {
        padding-top: 0px;
        margin-top: -12px;
        thead > tr {
          background: transparent !important;
          border-top: 1px solid #ccc;
          th {
            background: transparent !important;
            padding: 0.5rem 1rem;
            font-size: 12px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.54);
            font-style: italic;
            text-transform: none;
            letter-spacing: normal;
            cursor: pointer;
            &.asc:after {
              content: "\25B2";
            }
            &.desc:after {
              content: "\25BC";
            }
          }
        }
        tr {
          &.row-selected {
            background: #dae6fe !important;
            .cell-type {
              display: flex;
              align-content: center;
              justify-content: center;
              .fake-checkbox {
                background: #dae6fe;
                border-radius: 0;
                width: 30px;
                height: 30px;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                .checked {
                  position: relative;
                  font-size: 10px;
                  width: 16px;
                  height: 16px;
                  border-radius: 2px;
                  border: #333 2px solid;
                  background: #333;
                  box-shadow: 0 0 3px rgb(0 0 0 / 19%);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  align-content: center;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .material-icons {
                    font-size: 14px;
                  }
                }
              }
            }
          }
          &:hover {
            background: #dae6fe !important;
            cursor: pointer;
            .fake-checkbox {
              background: #f1f1f190;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              display: flex;
              align-content: center;
              justify-content: center;
              align-items: center;
              .checked {
                position: relative;
                font-size: 10px;
                width: 16px;
                height: 16px;
                border-radius: 2px;
                border: #333 2px solid;
                background: transparent;
                box-shadow: 0 0 3px rgb(0 0 0 / 19%);
                cursor: pointer;
              }
            }
          }
          td {
            padding: 0.25rem 1rem;
            .cell-type {
              display: flex;
              align-content: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              position: relative;
              max-height: 190px;
              margin: 0px !important;
              padding: 0px !important;
              text-align: center;
              /* center checkbox horizontally */
              vertical-align: middle;
              .folder-application {
                position: absolute;
                font-size: 10px;
                bottom: 0px;
                right: -2px;
              }
              .checkbox {
                display: flex;
                align-content: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                position: relative;
                align-items: center;
                flex-direction: row;
                padding: 0px !important;
                input {
                  margin: auto 100%;
                }
              }
            }
          }
        }
      }
    }
    .finder-content {
      margin-top: -12px;
      > div {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        .ged-grid-item-folder {
          display: flex;
          width: 200px;
          height: 50px;
          justify-content: space-between;
          align-items: center;
          border: solid 2px #e5e5e5;
          border-radius: 5px;
          padding: 10px;
          margin: 15px 15px 15px 0px;
          margin-top: 15px;
          overflow: hidden;
        }
        .ged-grid-item {
          display: flex;
          align-content: center;
          align-items: center;
          border-radius: 6px;
          border: 2px solid #dadce0;
          position: relative;
          cursor: pointer;
          .folder-application {
            position: absolute;
            bottom: 0px;
            right: 0px;
            font-size: 15px !important;
          }
          .material-icons {
            margin: 0.5rem;
            font-size: 32px;
            color: #98acce;
          }
          .name {
            margin: 0px;
            padding: 0px;
            font-weight: 500;
          }
          .file-hover {
            position: relative;
            width: 100%;
            &:hover {
              transition-duration: 0.3s;
              z-index: 1;
              .enableBlur {
                filter: blur(4px);
              }
            }
            .file-img {
              border-bottom: 1px solid #dadce0;
              div {
                -webkit-border-radius: 6px;
                border-radius: 6px;
              }
            }
            .file-text {
              overflow: hidden;
              max-height: 30px;
              line-height: 30px;
            }
            .file-tools {
              filter: blur(0px);
              position: absolute;
              top: 0px;
              z-index: 1;
              display: none;
              width: 100%;
              height: 100%;
              align-content: center;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }
            &:hover .file-tools {
              display: flex !important ;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  border: #bad0fb 2px dashed;
}
</style>
