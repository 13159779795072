<template lang="pug">
div.h-100.w-100
    .d-flex.flex-column
      #nav-sticky-horizontal.d-flex.justify-content-between.align-items-center.w-100.p-1
        ul.d-flex.space-between.w-100(style="height: 32px; overflow: hidden")
          li
            a.h-100.p-0.mr-1.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == 'general' ? 'current' : ''" @click="tabActive = 'general'")
              span Général
          li(v-if="checkFunctionUser(100, 'all')")
            a.h-100.p-0.mr-1.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == 'indicators' ? 'current' : ''" @click="tabActive = 'indicators'")
              span Indicateurs
          li
            a.h-100.p-0.mr-1.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == 'internalNote' ? 'current' : ''" @click="tabActive = 'internalNote'")
              span Notes internes
              feather-icon.align-middle.ml-50(v-if="companyForm.internalNote" icon='MessageCircleIcon')
        div.d-flex.align-items-center.ml-auto
            ejs-dropdownbutton(cssClass="e-outline mr-1" :select="selectParamerterAction" :items="parametersActionList" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icons e-settings"  :isPrimary="true" )
            ejs-button(v-on:click.native="saveCompany()" content="Enregistrer" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")
    .d-flex.flex-column.h-100.w-100(v-if='isLoadingCompany || isCreatingCompany || isUpdatingCompany' style="height: calc(100% - 55px);")
        .text-center.flex-center
            .loading-bg-inner
              .loader
                .outer
                .middle
                .inner
            .mt-5
                br
                br
                br
                |   {{ isLoadingCompany ? 'Chargement des détails du '+getTypeAtSingular(companyType)+'...' : 'Enregistrement du '+getTypeAtSingular(companyType)+'...' }} 
    .d-flex.w-100.content-wrapper(v-else style="height: calc(100% - 61px); overflow-y: auto;")
        .d-flex.h-100.w-100(v-if="tabActive == 'general'")
            edit-company-general(:companyId="companyId" :companyForm="companyForm")
        .d-flex.h-100.w-100(v-if="tabActive == 'indicators' && checkFunctionUser(100, 'all')")
            edit-company-indicators(:companyId="companyId" :companyForm="companyForm")
        .d-flex.h-100.w-100(v-if="tabActive == 'internalNote'")
            edit-company-notes(:companyId="companyId" :companyForm="companyForm")
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import vSelect from "vue-select";

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import EditCompanyGeneral from "@/components/directory/company/EditCompanyGeneral";
import EditCompanyDetails from "@/components/directory/company/EditCompanyDetails";
import EditCompanyIndicators from "@/components/directory/company/EditCompanyIndicators";
import EditCompanyNotes from "@/components/directory/company/EditCompanyNotes";
import { ECivility } from "@/types/api-orisis/enums/enums";

import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    EditCompanyGeneral,
    EditCompanyDetails,
    EditCompanyIndicators,
    EditCompanyNotes,
  },
  props: {
    companyId: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      title: "Description",
      name: "companyCustomFields",
      tabActive: "general",

      parametersActionList: [
        {
          text: "Archiver",
          iconCss: "e-btn-sb-icons e-icon-columns",
          type: "archive",
        },
      ],
      optionCivility: [
        {
          label: "M. et Mme",
          value: ECivility.MonsieurMadame,
        },
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },

        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      companyForm: {},
      companyType: {},
      dataOrigine: {},
    };
  },
  computed: {
    ...mapGetters([
      "isLoadingCompany",
      "isCreatingCompany",
      "isUpdatingCompany",
      "company",
      "isLoadingUser",
      "usersList",
      "isLoadingCollaboratorsList",
      "collaboratorsList",
      "companiesList",
    ]),
  },
  async created() {
    this.companyForm = this.company;
    this.companyForm.companyTypeId = this.company.companyType.id;
    this.companyForm.contactIds = this.company.contacts
      ? this.company.contacts.map((elem) => {
          return elem.contact.id;
        })
      : null;
    this.companyType = this.company.companyType;
    this.dataOrigine = JSON.stringify(this.companyForm);

    if (this.checkFunctionUser(100, "all")) {
      await this.getCompanyIndicators({});
    }
    this.getCollaborators({});
  },
  methods: {
    ...mapActions([
      "updateCompany",
      "getCollaborators",
      "getCompanyIndicators",
      "archiveCompanies",
    ]),
    checkFunctionUser,
    selectParamerterAction(args) {
      if (args.item.type == "archive") {
        this.archiveCompanyLocal(this.companyId, this.companyForm.name);
      }
    },
    async saveCompany() {
      const codeAlreadyExist = this.companiesList.find(
        (company) =>
          company.code === this.companyForm.code &&
          company.id !== this.companyForm.id
      );
      if (!codeAlreadyExist || !this.companyForm.code) {
        if (this.companyForm.isParticular == true) {
          let civility = this.optionCivility.find(
            (elem) => elem.value == this.companyForm.civility
          ).label;
          this.companyForm.name =
            (civility == "Autre" ? "" : civility + " ") +
            (this.companyForm.firstName ? this.companyForm.firstName : "") +
            " " +
            (this.companyForm.lastName ? this.companyForm.lastName : "");
        } else {
          this.companyForm.firstName = null;
          this.companyForm.lastName = null;
          this.companyForm.civility = 2;
        }
        await this.updateCompany({
          company: {
            ...this.companyForm,
            code: this.companyForm.code ? this.companyForm.code : null,
          },
        });
      } else {
        this.$bvModal.msgBoxConfirm("Veuillez mettre un autre code client.", {
          title: `Le code client ${this.companyForm.code} existe déjà.`,
          size: "sm",
          okTitle: "D'accord",
          okVariant: "primary",
          cancelTitle: "Fermer",
          cancelVariant: "outline-danger",
          hideHeaderClose: true,
          centered: true,
        });
      }
    },
    getTypeAtSingular(companyType) {
      return companyType.label.slice(0, -1).toLowerCase();
    },
    archiveCompanyLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title:
              "Êtes-vous sûr de vouloir archiver le " +
              this.getTypeAtSingular(this.companyType) +
              ' "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveCompanies({ companyIds: [id] }).then((res) => {
              this.$tabs.close({ to: "/companies" });
              this.$bvToast.toast(
                "Le " +
                  this.getTypeAtSingular(this.companyType) +
                  " a été archivée avec succès.",
                {
                  title: this.getTypeAtSingular(this.companyType) + " archivée",
                  variant: "success",
                  solid: true,
                }
              );
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.e-btn {
  display: flex;
  align-items: center;
  height: 32px;
}
@media screen and (max-width: 859px) {
  #nav-sticky-horizontal {
    flex-direction: column-reverse;
    padding-bottom: 0 !important;

    & > div {
      justify-content: flex-start !important;
      width: 100%;
      padding-bottom: 1rem;
    }

    & a span {
      max-width: 70px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .content-wrapper {
    height: calc(100% - 93px) !important;
  }
}
</style>
