<template lang="pug">
div.h-100
  .h-100(v-if='isLoadingReceiptFormProvider || isUpdatingReceiptFormProvider || isCreatingReceiptFormProvider')
    .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      div(style="margin-top: 7rem;")
        | {{ loadingMsg }}
  .h-100(v-else)
    .sidebar-header.pt-1.px-2
      div
        h3 {{ 'Réception : ' + (receiptFormProvider.documentReference ? receiptFormProvider.documentReference : 'Numéro en attente') + (receiptFormProvider.receiptFormStep ? ' #' + receiptFormProvider.receiptFormStep : '') }}
      feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
    .sidebar-header.pb-1.px-2
      .d-flex.align-items-center
        Status(:statusId="receiptFormProvider.status" nature="receiptFormProvider")
        span {{ receiptFormProvider.documentDate ? "En date du : " + dayjs(receiptFormProvider.documentDate).format("DD/MM/YYYY") : "" }}
      .end
    vs-divider.m-0
    .sidebar-action.py-05.px-2
      .d-flex.align-items-center.justify-content-between.w-100
        .d-flex
          b-button-group
            b-button.btn-icon.d-flex(variant='primary' @click="editDocument(receiptFormProvider.id, 'receipt-form-provider-pdf')" :style="'border-right:1px solid #ccc !important; height:37px'")
              feather-icon(icon='EyeIcon')
            b-button.btn-icon.d-flex(v-if='receiptFormProvider.status < 3' style='border-left:1px solid #ccc !important;' variant='primary' @click="editDocument(receiptFormProvider.id, 'receipt-form-provider-edit')" :disabled="!isLastReceiptForm && initialOrderFormProvider.status !== 8")
              feather-icon(icon='EditIcon')
              span(style='margin-left:3px') Modifier
            b-button.btn-icon.d-flex(v-if='receiptFormProvider.status >= 3 && receiptFormProvider.path' @click.stop='forceFileDownload(receiptFormProvider.path)' style='border-left:1px solid #ccc !important;' variant='primary')
              feather-icon.align-middle(icon='DownloadIcon')
              span(style='margin-left:3px') Télécharger
        .d-flex.align-items-center.justify-end
          b-button-group
            b-button( variant="outline-danger" v-if="receiptFormProvider.status == 0 || receiptFormProvider.status == 1" class="btn-icon" @click="deleteDocumentLocal(receiptFormProvider.id, receiptFormProvider.documentReference)")
              feather-icon.mr-50(icon="TrashIcon") 
              | Supprimer définitivement
            b-dropdown.d-none.dropdown-icon-wrapper(variant='outline-primary' left='')
              template(#button-content='')
                feather-icon.align-middle(icon='MoreHorizontalIcon')
              b-dropdown-item.d-none(@click.stop='duplicateDocument') Dupliquer
              b-dropdown-divider
              b-dropdown-item(@click.stop='cancelDocument' v-if="receiptFormProvider.status > 2 && receiptFormProvider.status !== 4") Annuler
              b-dropdown-item(@click.stop='deleteDocumentLocal(receiptFormProvider.id, receiptFormProvider.documentReference)' v-if="receiptFormProvider.status < 3") Supprimer
    vs-divider.m-0
    b-row.content-scrollable-sticky.m-0.justify-content-center
        b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
          ul.d-flex.border-bottom-grey-light.px-1
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                span Détails
            li(v-if="haveGedModule")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'files' ? 'current' : ''" @click="tabActive = 'files'")
                span Documents
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notes' ? 'current' : ''" @click="tabActive = 'notes'")
                span Notes
                feather-icon.align-middle.ml-50(v-if="receiptFormProvider.internalNote" icon='MessageCircleIcon')
    .sidebar-content.with-tabs
      div(v-if='tabActive == "details"')
        div(v-if='receiptFormProvider.companyProviderId')
          .d-flex.justify-content-between
            h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editCompany(receiptFormProvider.companyProviderName, 'provider')" @mouseover="showCompanyProviderEdit=true" @mouseleave="showCompanyProviderEdit=false" style="flex: 1;")
              span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                feather-icon(icon='ChevronRightIcon') 
                | {{ receiptFormProvider.companyProviderName }}
                feather-icon.ml-50(v-show="showCompanyProviderEdit" icon='ExternalLinkIcon')
            .d-flex
              div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
              div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                div.px-50
                  small Fournisseur
          div
            span(v-show='receiptFormProvider.companyProviderAddress') {{ receiptFormProvider.companyProviderAddress }}
              br
            span(v-show='receiptFormProvider.companyProviderAddressComplement') {{ receiptFormProvider.companyProviderAddressComplement }}
              br
            span(v-show='receiptFormProvider.companyProviderZipCode || receiptFormProvider.companyProviderCity') {{ receiptFormProvider.companyProviderZipCode }} {{ receiptFormProvider.companyProviderCity }}
              br
            span(v-show='receiptFormProvider.companyProviderCountry') {{ receiptFormProvider.companyProviderCountry }}
          ul.listTab.mt-1
            li(v-if='receiptFormProvider.companyProviderEmail')
              span Email
              span {{ receiptFormProvider.companyProviderEmail }}
            li(v-if='receiptFormProvider.companyProviderPhoneNumber')
              span Téléphone
              span {{ receiptFormProvider.companyProviderPhoneNumber }}
          vs-divider
        div(v-if='receiptFormProvider.storageLocationId || receiptFormProvider.deliverToAffairAddress')
          .d-flex.justify-content-between
              h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editStorageLocation(receiptFormProvider.storageLocationId)" @mouseover="showStorageLocationEdit=true" @mouseleave="showStorageLocationEdit=false" style="flex: 1;")
                .d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ receiptFormProvider.storageLocationLabel }}
                  feather-icon.ml-50(v-show="showStorageLocationEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Livraison
          div
            span(v-show='receiptFormProvider.storageLocationAddress') {{ receiptFormProvider.storageLocationAddress }}
              br
            span(v-show='receiptFormProvider.storageLocationAddressComplement') {{ receiptFormProvider.storageLocationAddressComplement }}
              br
            span(v-show='receiptFormProvider.storageLocationZipCode || receiptFormProvider.storageLocationCity') {{ receiptFormProvider.storageLocationZipCode }} {{ receiptFormProvider.storageLocationCity }}
              br
            span(v-show='receiptFormProvider.storageLocationCountry') {{ receiptFormProvider.storageLocationCountry }}
          ul.listTab.mt-1(v-if='receiptFormProvider.storageLocationId')
            li(v-if='receiptFormProvider.storageLocationEmail')
              span Email
              span {{ receiptFormProvider.storageLocationEmail }}
            li(v-if='receiptFormProvider.storageLocationPhoneNumber')
              span Téléphone
              span {{ receiptFormProvider.storageLocationPhoneNumber }}
            li(v-if='receiptFormProvider.deliverToAffairAddress')
              span Adresse
              span Livré sur le lieu de l'affaire
            li(v-if='receiptFormProvider.receiptDate')
              span Réceptionné le
              span {{ dayjs(receiptFormProvider.receiptDate).format("DD/MM/YYYY")  }}
          vs-divider 
        .d-flex.mb-50.align-items-center(v-if="receiptFormProvider.orderFormId && initialOrderFormProvider")
          div.d-flex.align-items-center.w-100.justify-content-between
            .d-flex
              span.material-icons.text-gray(style="font-size:20px") description
              div
                span.ml-50.font-weight-bold
                  | Sur bon de commande {{ initialOrderFormProvider && initialOrderFormProvider.documentReference ? initialOrderFormProvider.documentReference : null}}
            ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectReceiptForm" :items="itemsComputed({id: initialOrderFormProvider.id, documentReference: initialOrderFormProvider.documentReference})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
        vs-divider
        div(v-if='receiptFormProvider.companyCustomerId')
          .d-flex.justify-content-between.mb-1
              h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(@click="editCompany(receiptFormProvider.companyCustomerId, 'client')" @mouseover="showCompanyCustomerEdit=true" @mouseleave="showCompanyCustomerEdit=false" style="flex: 1;")
                span(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ receiptFormProvider.companyCustomerName }}
                  feather-icon.ml-50(v-show="showCompanyCustomerEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Client lié
        div(v-if='receiptFormProvider.affairId')
          .d-flex.justify-content-between.mb-1
              h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(@click="editAffair(receiptFormProvider.affairId)" @mouseover="showAffairEdit=true" @mouseleave="showAffairEdit=false" style="flex: 1;")
                span(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ receiptFormProvider.affairName }}
                  feather-icon.ml-50(v-show="showAffairEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Affaire liée
        vs-divider(v-if='receiptFormProvider.affairId || receiptFormProvider.companyCustomerId')
        div
          HistoryReceiptFormProvider          
      div.h-100(v-if='tabActive == "files"')
        ged-viewer-sidebar(:parentFolder="receiptFormProvider.folderId" :fromModule="'receiptFormProvider'")
      div.h-100(v-if='tabActive == "notes"')
        quill-editor(id="text-area-designation" v-model="receiptFormProvider.internalNote" toolbar="essential" @blur="updateReceiptFormProviderWithoutLoading" class="editor quill-fixed" :options="editorOption" style="height: calc(100% - 55px);")
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import axios from "axios";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import Status from "@/components/global/status/Status";
import HistoryReceiptFormProvider from "@/components/purchase/receipt-form-provider/HistoryReceiptFormProvider";
import GedViewerSidebar from "@/components/ged/GedViewerSidebar";
import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  data() {
    return {
      tabActive: "details",
      dayjs: dayjs,
      cssClass: "tool-tip-error",
      loadingTitle: "Chargement",
      loadingMessage: "Chargement des détails du bon de réception...",
      showStorageLocationEdit: false,
      showCompanyProviderEdit: false,
      showCompanyCustomerEdit: false,
      showAffairEdit: false,
      editorOption: {
        placeholder: "Ecrivez une note interne liée à ce document ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        },
      },
      dataOrigine: {},
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingReceiptFormProvider",
      "isCreatingReceiptFormProvider",
      "isLoadingReceiptFormProvider",
      "isDeletingReceiptFormProvider",
      "isLoadingReceiptFormProviderPDF",
      "isLoadingDuplicatingDocument",
      "workspaceSelected",
      "initialOrderFormProvider",
    ]),
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getReceiptFormProviderDetails"];
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM_DETAILS", value);
      },
    },
    isLoadingDuplicatingDocument: {
      get() {
        return this.$store.getters["isLoadingDuplicatingReceiptFormProvider"];
      },
      set(value) {
        return this.$store.commit(
          "SET_IS_LOADING_DUPLICATING_RECEIPT_FORM",
          value
        );
      },
    },
    loadingMsg: {
      get() {
        return this.$store.getters["loadingMsg"];
      },
      set(value) {
        return this.$store.commit("SET_LOADING_MSG", value);
      },
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
    isLastReceiptForm() {
      if (
        this.initialOrderFormProvider &&
        this.initialOrderFormProvider.receiptFormProviders &&
        this.receiptFormProvider.id
      ) {
        let find = this.initialOrderFormProvider.receiptFormProviders.find(
          (item) => item.id == this.receiptFormProvider.id
        );
        let receiptFormProviders =
          this.initialOrderFormProvider.receiptFormProviders.filter(
            (item) => item.status != 4
          );
        if (!find) return true;
        for (let i = 0; i < receiptFormProviders.length; i++) {
          const receiptFormProvider = receiptFormProviders[i];
          if (receiptFormProvider.receiptFormStep > find.receiptFormStep) {
            return false;
          }
        }
      }
      return true;
    },
  },
  methods: {
    ...mapActions([
      "getReceiptFormById",
      "updateReceiptForm",
      "deleteReceiptFormProviders",
      "duplicateReceiptFormProvider",
      "getReceiptFormProviderFromReceiptFormProviderId",
    ]),
    checkFunctionUser,
    async updateReceiptFormProviderWithoutLoading() {
      if (
        this.dataOrigine.internalNote !== this.receiptFormProvider.internalNote
      ) {
        await this.updateReceiptForm({
          receiptFormProvider: this.receiptFormProvider,
          loading: false,
        });
      }
    },
    async newReceiptFormProvider() {
      //create empty order form
      await this.getReceiptFormProviderFromReceiptFormProviderId(
        this.receiptFormProvider.id
      ).then((receiptFormProvider) => {
        this.$router.push({
          name: "new-receipt-form-provider",
          params: {
            id: receiptFormProvider.id,
            title: "Nouveau bon de réception",
            tips: "Nouveau bon de réception",
            routeOrigine: "receipt-form-providers",
          },
        });
        this.closeSidebar();
      });
    },
    async editDocument(id, mode) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-receipt-form-provider",
        params: {
          mode: mode,
          id: id,
          title: this.receiptFormProvider.documentReference
            ? this.receiptFormProvider.documentReference
            : "Editer un bon de réception",
          tips:
            "Editer le bon de réception" +
            this.receiptFormProvider.documentReference
              ? " : " + this.receiptFormProvider.documentReference
              : "",
          routeOrigine: "receipt-form-providers",
        },
      });
    },
    async showAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "affair",
        params: {
          id: id,
          title: "Affaire : " + this.affair.name,
          tips: "Affaire : " + this.affair.name,
          routeOrigine: "affairs",
        },
      });
    },
    async editStorageLocation(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-storage-location",
        params: {
          id: id,
          title: "Edit : " + this.receiptFormProvider.storageLocationLabel,
          tips:
            "Editer le point de stockage : " +
            this.receiptFormProvider.storageLocationLabel,
          routeOrigine: "stock/storage-locations",
        },
      });
    },
    async editCompany(id, type) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title:
            "Edit : " + type == "client"
              ? this.receiptFormProvider.companyCustomerName
              : this.receiptFormProvider.companyCustomerName,
          tips:
            "Editer l'entreprise : " + type == "client"
              ? this.receiptFormProvider.companyCustomerName
              : this.receiptFormProvider.companyProviderName,
          routeOrigine: "companies",
        },
      });
    },
    async getDocumentDetails(id, nature) {
      if (id != "") {
        this.getReceiptFormById({ receiptFormId: id }).then((res) => {
          this.dataOrigine = JSON.parse(JSON.stringify(res));
        });
        this.tabActive = "details";
      }
    },
    duplicateDocument() {
      this.loadingMsg = "Duplication en cours...";
      this.isLoadingDuplicatingDocument = true;
      this.duplicateReceiptFormProvider({
        receiptFormId: this.receiptFormProvider.id,
      }).then(async (res) => {
        this.closeSidebar();
        this.isLoadingDuplicatingDocument = false;
        this.loadingMsg = "Chargement en cours...";
        this.$router.push({
          name: "new-receipt-form-provider",
          params: {
            id: res.data.id,
            title: "Nouveau bon de réception",
            tips: "Nouveau bon de réception",
            routeOrigine: "receipt-form-providers",
          },
        });
      });
    },
    async cancelDocument() {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir supprimer ce bon de réception ?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.updateReceiptForm({
              receiptFormProvider: {
                ...this.receiptFormProvider,
                status: 4,
                path: "",
              },
              loading: false,
            }).then(() => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    deleteDocumentLocal(id, reference) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            'Êtes-vous sûr de vouloir supprimer le bon de réception "' +
            reference +
            '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteReceiptFormProviders({
              receiptFormProviderIds: [id],
            }).then(() => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    itemsComputed(args) {
      return [
        {
          text: "Visualiser",
          id: args.id,
          documentReference: args.documentReference,
        },
        // {
        //   text: 'Télécharger',
        //   id: args.id,
        //   documentReference: args.documentReference,
        //   nature: args.nature ? args.nature : null
        // },
      ];
    },
    onSelectReceiptForm(args) {
      this.closeSidebar();
      if (args.item.text == "Visualiser") {
        this.$router.push({
          name: "edit-order-form-provider",
          params: {
            mode: "document-pdf",
            id: args.item.id,
            title: args.item.documentReference
              ? args.item.documentReference
              : "Editer un bon de commande",
            tips:
              "Editer le bon de commande" + args.item.documentReference
                ? " : " + args.item.documentReference
                : "",
          },
        });
      } else {
      }
    },

    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute(
          "download",
          this.receiptFormProvider.documentReference
        );
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  mounted() {
    this.loadingTitle = "Chargement";
    this.loadingMessage = "Chargement des détails du bon de réception...";
  },
  components: {
    VuePerfectScrollbar,
    quillEditor,
    HistoryReceiptFormProvider,
    Status,
    GedViewerSidebar,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}

.cancel-modal-buttons {
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;

    .dropdown-content-item {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdownbutton-redirect {
  background-color: #0000;
  border-color: #0000;
  color: #5c636a;
}
</style>
