<template>
  <div class="vh-100">
    <div
      v-if="isLoadingAffair || isUpdatingAffair || isDeletingAffair"
      class="h-100"
    >
      <div class="text-center flex-center h-100">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div style="margin-top: 7rem">
          Chargement des détails de l'affaire...
        </div>
      </div>
    </div>
    <div v-else class="vh-100">
      <div class="sidebar-header py-1 px-2">
        <div>
          <h3>{{ affair.name }}</h3>
          <small v-show="affair.code">Code : {{ affair.code }}</small>
        </div>
        <feather-icon
          icon="XIcon"
          size="24"
          @click.stop="closeSidebar"
          class="close-icon cursor-pointer"
        ></feather-icon>
      </div>
      <vs-divider class="m-0" />
      <div class="sidebar-action py-05 px-2">
        <div class="d-flex justify-content-between w-100">
          <b-button
            class="btn-icon"
            v-if="archived"
            variant="warning"
            @click="restoreAffairLocal(affair.id, affair.name)"
          >
            <feather-icon icon="ArrowDownIcon">Restaurer</feather-icon>
          </b-button>
          <b-button-group v-else>
            <b-button
              @click="showAffair(affair.id)"
              class="btn-icon d-flex"
              variant="primary"
            >
              <feather-icon icon="EyeIcon"> </feather-icon>
              <span style="margin-left: 3px">Gérer</span>
            </b-button>
          </b-button-group>
          <b-button
            v-if="!archived"
            title="Archiver"
            class="btn-icon"
            variant="outline-danger"
            @click="archiveAffairLocal(affair.id, affair.name)"
          >
            <feather-icon icon="ArchiveIcon"></feather-icon>
          </b-button>
        </div>
      </div>
      <vs-divider class="m-0" />
      <b-row class="content-scrollable-sticky m-0 justify-content-center">
        <b-col
          id="nav-sticky-horizontal"
          style="padding: 0 !important; height: 100% !important"
          cols="12"
        >
          <ul class="d-flex border-bottom-grey-light px-1">
            <li>
              <a
                class="
                  d-flex
                  align-center align-content-center align-items-center
                  w-100
                "
                :class="tabActive == 'details' ? 'current' : ''"
                @click="tabActive = 'details'"
              >
                <span>Détails</span>
              </a>
            </li>
            <li>
              <a
                class="
                  d-flex
                  align-center align-content-center align-items-center
                  w-100
                "
                :class="tabActive == 'notes' ? 'current' : ''"
                @click="tabActive = 'notes'"
              >
                <span>Notes</span>
                <feather-icon
                  class="align-middle ml-50"
                  v-if="affair.internalNote"
                  icon="MessageCircleIcon"
                />
              </a>
            </li>
          </ul>
        </b-col>
      </b-row>
      <div class="sidebar-content">
        <div v-if="tabActive == 'details'">
          <div
            v-show="affair.startDate || affair.endDate || affair.description"
          >
            <p class="paraphbox bg-white" v-if="affair.description">
              <span v-html="affair.description"></span>"
            </p>
            <ul class="listTab" v-if="affair.startDate || affair.endDate">
              <li>
                <span>Date de début</span>
                <span>{{
                  affair.startDate
                    ? new Date(affair.startDate).toLocaleDateString()
                    : ""
                }}</span>
              </li>
              <li>
                <span>Date de fin</span>
                <span>{{
                  affair.endDate
                    ? new Date(affair.endDate).toLocaleDateString()
                    : ""
                }}</span>
              </li>
            </ul>
            <vs-divider />
          </div>
          <div v-if="affair.company">
            <h4 class="mb-1 text-primary d-flex justify-content-between">
              <span> <feather-icon icon="ChevronRightIcon" /> Client </span>
              <span>
                {{ affair.company.name }}
                <feather-icon
                  icon="ExternalLinkIcon"
                  class="cursor-pointer"
                  @click="editCompany(affair.company.id)"
                />
              </span>
            </h4>
            <ul class="listTab">
              <li v-if="affair.company.email">
                <span>Email</span>
                <span>{{ affair.company.email }}</span>
              </li>
              <li v-if="affair.company.phoneNumber">
                <span>Téléphone</span>
                <span>{{ affair.company.phoneNumber }}</span>
              </li>
              <li v-if="affair.company.secondaryPhoneNumber">
                <span>Tél secondaire</span>
                <span>{{ affair.company.secondaryPhoneNumber }}</span>
              </li>
            </ul>
            <vs-divider />
          </div>
          <div
            v-show="
              affair.address || affair.zipCode || affair.city || affair.country
            "
          >
            <h4 class="mb-1 text-primary">
              <feather-icon icon="MapPinIcon" /> Adresse
            </h4>
            <p class="paraphbox">
              <span v-show="affair.address">{{ affair.address }}<br /></span>
              <span v-show="affair.addressComplement"
                >{{ affair.addressComplement }}<br
              /></span>
              <span v-show="affair.zipCode || affair.city"
                >{{ affair.zipCode }} {{ affair.city }}<br
              /></span>
              <span v-show="affair.country">{{ affair.country }}</span>
            </p>
            <vs-divider />
          </div>
          <div
            v-show="
              affair.job ||
              affair.contract ||
              affair.matricule ||
              affair.socialSecurityNumber
            "
          >
            <h4 class="mb-1 text-primary">
              <feather-icon icon="ClipboardIcon" /> Juridique
            </h4>
            <ul class="listTab">
              <li v-if="affair.job">
                <span>Métier</span>
                <span>{{ affair.job.label }}</span>
              </li>
              <li v-if="affair.contract">
                <span>Contrat</span>
                <span>{{ affair.contract.label }}</span>
              </li>
              <li v-show="affair.matricule">
                <span>Matricule</span>
                <span>{{ affair.matricule }}</span>
              </li>
              <li v-show="affair.socialSecurityNumber">
                <span>N° Sécurité Sociale</span>
                <span>{{ affair.socialSecurityNumber }}</span>
              </li>
            </ul>
            <vs-divider />
          </div>
          <div
            class="w-full d-flex justify-content-between mt-1"
            v-if="affair.latitude && affair.latitude"
          >
            <GmapMap
              class="w-100 card-map"
              ref="gmap"
              :center="{ lat: affair.latitude, lng: affair.longitude }"
              :zoom="15"
              style="height: 220px"
            >
              <GmapMarker
                :position="{ lat: affair.latitude, lng: affair.longitude }"
                :clickable="true"
                :draggable="true"
                @click="
                  center = { lat: affair.latitude, lng: affair.longitude }
                "
              >
              </GmapMarker>
            </GmapMap>
          </div>
        </div>
        <div style="height: calc(100% - 100px)" v-if="tabActive == 'notes'">
          <ejs-richtexteditor
            ref="defaultRTE"
            :height="'calc(100vh - 232px)'"
            id="text-area-designation"
            v-model="affair.internalNote"
            @blur="updateDocumentWithoutLoading"
          >
          </ejs-richtexteditor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import {
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
  Table,
  FileManager,
  EmojiPicker,
} from "@syncfusion/ej2-vue-richtexteditor";

export default {
  data() {
    return {
      archived: false,
      affair: {},
      tabActive: "details",
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingAffair",
      "affairsList",
      "isLoadingAffair",
      "isDeletingAffair",
    ]),
  },
  methods: {
    ...mapActions([
      "updateAffair",
      "deleteAffairs",
      "isArchivingAffair",
      "archiveAffairs",
      "getAffairById",
      "getAffairArchivedById",
      "restoreAffairs",
    ]),
    updateDocumentWithoutLoading() {
      this.updateAffair({
        affair: { ...this.affair, internalNote: this.affair.internalNote },
        upadateState: false,
        route: "affair",
      });
    },
    getAffairArchivedDetails(id, archived) {
      this.archived = archived;
      if (id != "") {
        this.getAffairArchivedById({ affairId: id }).then((res) => {
          this.affair = res;
        });
      } else {
        this.affair = {};
      }
    },
    getAffairDetails(id) {
      this.archived = false;
      if (id != "") {
        this.getAffairById({ affairId: id }).then((res) => {
          this.affair = res;
        });
      } else {
        this.affair = {};
      }
    },

    async showAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "affair",
        params: {
          id: id,
          title: "Affaire : " + this.affair.name,
          tips: "Affaire : " + this.affair.name,
          routeOrigine: "affairs",
        },
      });
    },
    async editAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-affair",
        params: {
          id: id,
          title: "Edit : " + this.affair.name,
          tips: "Editer le collaborateur : " + this.affair.name,
          routeOrigine: "affairs",
        },
      });
    },
    async editCompany(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title:
            "Edit : " +
            (this.affair.company.name
              ? this.affair.company.name
              : this.affair.company.firstName +
                " " +
                this.affair.company.lastName),
          tips:
            "Editer l'entreprise : " +
            (this.affair.company.name
              ? this.affair.company.name
              : this.affair.company.firstName +
                " " +
                this.affair.company.lastName),
          routeOrigine: "affairs",
        },
      });
    },

    archiveAffairLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette affaire en consultant la liste de vos archives.",
          {
            title:
              "Êtes-vous sûr de vouloir archiver l'affaire \"" + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveAffairs({ affairIds: [id], route: "affair" }).then(
              (res) => {
                this.closeSidebar();
                this.$emit("refreshGrid");
              }
            );
          }
        });
    },
    restoreAffairLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title:
              "Êtes-vous sûr de vouloir restaurer l'affaire \"" + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreAffairs({ affairIds: [id], route: "affair" }).then(
              (res) => {
                this.closeSidebar();
                this.$emit("refreshGrid");
              }
            );
          }
        });
    },
    deleteAffairLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            "Êtes-vous sûr de vouloir supprimer l'affaire \"" + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteAffairs({ affairIds: [id], route: "affair" }).then(
              (res) => {
                this.closeSidebar();
                this.$emit("refreshGrid");
              }
            );
          }
        });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  provide: {
    richtexteditor: [Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar],
  },
};
</script>

<style>
@import "~@syncfusion/ej2-vue-richtexteditor/styles/bootstrap5.css";
</style>
