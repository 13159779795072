<template lang="pug">
div.h-100
  .h-100(v-if='isLoadingCreditProvider || isUpdatingCreditProvider')
    .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      div(style="margin-top: 7rem;")
        | Chargement des détails de l'avoir fournisseur...
  .h-100(v-else='')
    .sidebar-header.pt-1.px-2
      div 
        h3 {{ 'Avoir fournisseur : ' + (creditProvider.documentReference ? creditProvider.documentReference : 'en attente') }}
      feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
    .sidebar-header.pb-1.px-2
      .d-flex
        Status(:statusId="creditProvider.creditProviderStatus" nature="creditProvider")
        span {{ creditProvider.documentDate ? "En date du : " + dayjs(creditProvider.documentDate).format("DD/MM/YYYY") : "" }}
      .end
    vs-divider.m-0
    .sidebar-action.py-05.px-2
      .d-flex.align-items-center.justify-content-between.w-100
        .d-flex
          b-button-group
            b-button.btn-icon.d-flex(v-if="creditProvider.creditProviderStatus >= 2" variant='primary' @click="editCreditProvider(creditProvider.id)" :style="'border-right:1px solid #ccc !important;'")
              feather-icon(icon='EyeIcon')
            b-button.btn-icon.d-flex(v-else style='border-left:1px solid #ccc !important;' variant='primary' @click="editCreditProvider(creditProvider.id)")
              feather-icon(icon='EditIcon')
            b-button.btn-icon.d-flex(v-if='creditProvider.path' @click.stop='forceFileDownload(creditProvider.path)' style='border-left:1px solid #ccc !important;' variant='primary')
              feather-icon.align-middle(icon='DownloadIcon')
              span(style='margin-left:3px') Télécharger
        .d-flex.align-items-center.justify-end
          b-button-group
            b-dropdown.dropdown-icon-wrapper(variant='outline-primary' left='')
              template(#button-content='')
                feather-icon.align-middle(icon='MoreHorizontalIcon')
              b-dropdown-item(@click.stop='payedCreditProvider' v-if="creditProvider.creditProviderStatus == 2 || creditProvider.creditProviderStatus == 3") Ajouter un règlement
              b-dropdown-item(@click.stop='ignoreCreditProvider' v-if="creditProvider.creditProviderStatus == 0") Ignorer
              b-dropdown-item(@click.stop='deleteCreditProviderLocal(creditProvider.id)' v-if="creditProvider.creditProviderStatus < 2 || creditProvider.creditProviderStatus == 5") Supprimer
    vs-divider.m-0
    b-row.content-scrollable-sticky.m-0.justify-content-center
        b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
          ul.d-flex.border-bottom-grey-light.px-1
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                span Détails
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notes' ? 'current' : ''" @click="tabActive = 'notes'")
                span Notes
                feather-icon.align-middle.ml-50(v-if="creditProvider.internalNote" icon='MessageCircleIcon')
    .sidebar-content.with-tabs
      div.h-100(v-if='tabActive == "details"')
        div(v-if='creditProvider.provider')
          .d-flex.justify-content-between
            h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editCompany(creditProvider.provider.name, 'fournisseur')" @mouseover="showCompanyCustomerEdit=true" @mouseleave="showCompanyCustomerEdit=false" style="flex: 1;")
              span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                feather-icon(icon='ChevronRightIcon') 
                | {{ creditProvider.provider.name }}
                feather-icon.ml-50(v-show="showCompanyCustomerEdit" icon='ExternalLinkIcon')
            .d-flex
              div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
              div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                div.px-50
                  small Fournisseur
          div(v-for='(item, index) in creditProvider.provider.addresses')
            span(v-show='item.address') {{ item.address }}
              br
            span(v-show='item.addressComplement') {{ item.addressComplement }}
              br
            span(v-show='item.zipCode || item.city') {{ item.zipCode }} {{ item.city }}
              br
            span(v-show='item.country') {{ item.country }}
          ul.listTab.mt-1
            li(v-if='creditProvider.provider.email') 
              span Email
              span {{ creditProvider.provider.email }}
            li(v-if='creditProvider.provider.phoneNumber')
              span Téléphone
              span {{ creditProvider.provider.phoneNumber }}
        div(v-if='creditProvider && creditProvider.referentielTvas && creditProvider.referentielTvas.length > 0')
          vs-divider
          TotalCreditProvider
        div(v-if='creditProvider && creditProvider.creditProviderRepartitions && creditProvider.creditProviderRepartitions.length > 0')
          vs-divider 
          div.mb-2(v-for="(item, index) in creditProvider.creditProviderRepartitions" :key="index")
            .d-flex.justify-content-between
              .d-flex.flex-column.justify-content-end
                h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(style="flex: 1;")
                  span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                    feather-icon(icon='ChevronRightIcon') 
                    | {{ item.companyName }}
                span.text-primary.d-flex.align-items-center(style='min-width: fit-content; margin-top: 3px;')
                  | {{ item.affairName }}
              .d-flex.flex-column.justify-content-end
                .d-flex
                  div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                  div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content; justify-content: flex-end; display: flex")
                    div.px-50
                      small Répartition {{ index + 1 }}
                span.totalNetToPay.netToPay.text-right.text-primary.font-weight-bold(style="margin-right: 7px; margin-top: 3px;") {{ formatCurrency(item.priceHt) }} HT
        div.h-100.d-flex.justify-content-center.align-items-center(v-if="creditProvider && !creditProvider.provider && creditProvider.referentielTvas && !creditProvider.referentielTvas.length > 0")
          span Aucune donnée à afficher
      div.h-100(v-if='tabActive == "notes"')
        quill-editor(id="text-area-designation" v-model="creditProvider.internalNote" toolbar="essential" @blur="updateCreditProviderWithoutLoading" class="editor quill-fixed" :options="editorOption" style="height: calc(100% - 55px);")
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import axios from "axios";
import Status from "@/components/global/status/Status";
import TotalCreditProvider from "@/components/purchase/credit-provider/TotalCreditProvider";
import {
  formatCurrency,
  formatNumber,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      tabActive: "details",
      dayjs: dayjs,
      loadingTitle: "Chargement",
      loadingMessage: "Chargement des détails de l'avoir fournisseur...",
      showStorageLocationEdit: false,
      showCompanyCustomerEdit: false,
      showAffairEdit: false,
      editorOption: {
        placeholder: "Ecrivez une note interne liée à ce document ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        },
      },
      dataOrigine: {},
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingCreditProvider",
      "isLoadingCreditProvider",
      "isDeletingCreditProvider",
      "isLoadingCreditProviderPDF",
      "workspaceSelected",
    ]),
    creditProvider: {
      get() {
        return this.$store.getters.creditProvider;
      },
      set(value) {
        return this.$store.commit("SET_CREDIT_PROVIDER", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getCreditsProviderById",
      "updateCreditsProvider",
      "deleteCreditProviders",
    ]),
    formatCurrency,
    formatNumber,
    formatDate,
    async updateCreditProviderWithoutLoading() {
      if (this.dataOrigine.internalNote !== this.creditProvider.internalNote) {
        await this.updateCreditsProvider({
          creditProvider: this.creditProvider,
          updateStatus: false,
        });
      }
    },
    async editCreditProvider(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-credit-provider",
        params: {
          id: id,
          title:
            "Editer l'avoir fournisseur" +
            (this.creditProvider.documentReference
              ? " : " + this.creditProvider.documentReference
              : ""),
          tips:
            "Editer l'avoir fournisseur" +
            (this.creditProvider.documentReference
              ? " : " + this.creditProvider.documentReference
              : ""),
          routeOrigine: "credits-provider",
        },
      });
    },
    async editCompany(id, type) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title:
            "Edit : " + type == "fournisseur"
              ? this.creditProvider.provider.name
              : this.creditProvider.provider.name,
          tips:
            "Editer le fournisseur : " + type == "fournisseur"
              ? this.creditProvider.provider.name
              : this.creditProvider.provider.name,
          routeOrigine: "companies",
        },
      });
    },
    async getCreditProviderDetails(id) {
      if (id != "") {
        this.getCreditsProviderById({
          creditProviderId: id,
          getPaymentProviders: true,
          paymentProviderId: this.creditProvider.paymentProviderId,
        });
        this.tabActive = "details";
      }
    },
    async ignoreCreditProvider() {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: `Êtes-vous sûr de vouloir ignorer l'avoir fournisseur ?`,
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.updateCreditsProvider({
              creditProvider: {
                ...this.creditProvider,
                creditProviderStatus: 5,
              },
              updateStatus: false,
            }).then(() => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    deleteCreditProviderLocal(id) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: `Êtes-vous sûr de vouloir supprimer l'avoir fournisseur ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteCreditProviders({ creditProviderIds: [id] }).then(() => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute("download", this.creditProvider.documentReference);
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    payedCreditProvider() {
      this.closeSidebar();
      this.$router.push({
        name: "new-payment-provider",
        params: {
          id: 0,
          documentData: structuredClone(this.creditProvider),
          title: "Nouveau règlement fournisseur",
          routeOrigine: "credits-provider",
          type: 3,
          disabled: true,
        },
      });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  mounted() {
    this.loadingTitle = "Chargement";
    this.loadingMessage = "Chargement des détails de l'avoir fournisseur...";
  },
  components: {
    VuePerfectScrollbar,
    quillEditor,
    Status,
    TotalCreditProvider,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}

.cancel-modal-buttons {
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;

    .dropdown-content-item {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
