<template lang="pug">
  .h-100.d-flex.flex-column
    HeaderGrid(:name="name" :title='title' :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch")
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingAffairsListByCompany || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des produits...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebarAffair" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-affair-content(ref='sidebarAffairContent' @closeSidebar="closeSidebar" @refreshGrid="refreshGrid")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import { affairStatus } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

import SidebarAffairContent from "@/components/directory/affair/SidebarAffairContent";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarAffairContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      title: "Affaires",
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "affair",
      uid: "companyAffairs",
      statusList: affairStatus.filter((el) => !el.notUse),
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      documentSelected: {},

      tvaMode: "HT",
      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: true,
      searchPlaceholder: "Rechercher une affaire",
    };
  },
  computed: {
    ...mapGetters([
      "affairsListByCompany",
      "isLoadingAffairsListByCompany",
      "company",
    ]),
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.$store.getters[this.name + "sListByCompany"].filter(
          (elem) =>
            elem[this.name + "Tags"].find((tag) =>
              this.tagsSelected.includes(tag.id)
            )
        );
      } else {
        return this.$store.getters[this.name + "sListByCompany"];
      }
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.tagsSelected.length) {
            return this.$store.getters[this.name + "sListByCompany"].filter(
              (elem) =>
                elem[this.name + "sListByCompany"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                )
            );
          } else {
            return this.$store.getters[this.name + "sListByCompany"];
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.tagsSelected.length) {
            return this.$store.getters[this.name + "sListByCompany"].filter(
              (elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                ) &&
                (statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive)
            );
          } else {
            return this.$store.getters[this.name + "sListByCompany"].filter(
              (elem) =>
                statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive
            );
          }
        }
      } else {
        return [];
      }
    },
  },
  props: {
    companyId: {
      default: null,
      required: true,
    },
  },
  async created() {
    await this.getAffairsByCompanyId({
      companyId: this.companyId,
    }).then(async (res) => {
      this.setHeaderData();
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
    await this.getCompanies({});
    await this.getCollaborators({});
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getCollaborators",
      "getAffairsByCompanyId",
    ]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    setDates(e) {},
    async setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: true,
          showInColumnChooser: false,
        },
        {
          type: "string",
          field: "color",
          headerText: "Couleur",
          width: 60,
          maxWidth: 60,
          allowFiltering: false,
          allowSorting: true,
          showInColumnChooser: true,
          template: "colorTemplate",
          visible: true,
          unavailableExport: true,
        },
        {
          type: "string",
          field: "code",
          headerText: "Code",
          width: 100,
          maxWidth: 100,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          direction: "Descending",
        },
        {
          type: "string",
          field: "name",
          headerText: "Nom",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "description",
          headerText: "Description",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowGrouping: false,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          filter: { type: "Menu", operator: "contains" },
        },
        {
          type: "string",
          field: "affairTags",
          headerText: "Tags",
          width: "auto",
          minWidth: 200,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "tagsTemplate",
          unavailableExport: true,
        },
        {
          type: "string",
          field: "status",
          headerText: "Statut",
          width: 110,
          maxWidth: 110,
          template: "statusTemplate",
          nature: "affairStatus",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          filterTemplate: "statusFilterTemplate",
        },
        {
          type: "date",
          format: "formatDateOptions",
          field: "startDate",
          headerText: "Date de début",
          width: 125,
          maxWidth: 125,
          template: "dateYmdTemplate",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "date",
          format: "formatDateOptions",
          field: "endDate",
          headerText: "Date de fin",
          width: 125,
          maxWidth: 125,
          template: "dateYmdTemplate",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
      ];
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({
        name: "new-affair",
        params: {
          companyId: this.companyId,
        },
      });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              sum += obj["totalTtc"];
            } else {
              sum += obj["totalHt"];
            }
          });
      }
      return this.formatCurrency(sum);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarAffairContent"].getAffairDetails(
        event.rowData.id,
        event.rowData.nature
      );
      this.$refs.sidebarAffair.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    closeSidebar() {
      this.$refs.sidebarAffair.hide();
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
  },
};
</script>
