<template lang="pug">
  .h-100.d-flex.flex-column
    HeaderGrid(:name="name" userFilter="companyReceiptFormProviderFilters" :dateRangeDefault="dateRangeDefault" :title="title" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch")
    b-row.content-scrollable-sticky.m-0.justify-content-center(style="margin-top: -12px !important;")
      b-col#nav-sticky-horizontal.content-scrollable-sticky-inner(cols='12' style='padding: 0 !important; height: 100% !important')
        ul.d-flex
          li
            a.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == 'all' ? 'current' : ''" @click="tabActive = 'all'")
              span.text-nowrap Tout ({{ getCountDataByStatus("all") }})
          li(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
            a.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == status.value ? 'current' : ''" @click="tabActive = status.value")
              span.text-nowrap {{ status.label }} ({{ getCountDataByStatus(status.value) }})
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingReceiptFormProvidersListByCompany || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des bons de réception...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-receipt-form-provider-content(v-show="isOpen" ref='sidebarReceiptFormProviderContent' @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
    popup-new-receipt-form-provider(ref='popupNewReceiptForm' :companyId="companyId")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { receiptFormProviderStatus } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarReceiptFormProviderContent from "@/components/purchase/receipt-form-provider/SidebarReceiptFormProviderContent";
import PopupNewReceiptFormProvider from "@/components/purchase/receipt-form-provider/PopupNewReceiptFormProvider.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarReceiptFormProviderContent,
    SyncGrid,
    HeaderGrid,
    PopupNewReceiptFormProvider,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      title: "Bons de réception",
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "receiptForm",
      uid: "companyReceiptForms",
      statusList: receiptFormProviderStatus.filter((el) => !el.notUse),
      headerData: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      documentSelected: {},

      tvaMode: "HT",
      dateRanger: true,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: true,
      searchPlaceholder: "Rechercher un bon de réception",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 100,
        maxWidth: 100,
        template: "statusTemplate",
        nature: "receiptFormProviderStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "documentReference",
        headerText: "Numéro",
        width: 160,
        maxWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        allowGrouping: false,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "documentDate",
        headerText: "Date",
        width: 95,
        maxWidth: 95,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        field: "receiptDate",
        headerText: "Date de réception",
        width: 95,
        maxWidth: 95,
        format: "dd/MM/yyyy HH:mm",
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "number",
        field: "userId",
        filterTemplate: "userFilterTemplate",
        headerText: "Traité par",
        maxWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        valueAccessor: "user",
      },
      {
        type: "string",
        field: "companyProviderName",
        headerText: "Fournisseur",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "companyCustomerName",
        headerText: "Client",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "affairName",
        headerText: "Affaire",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "storageLocationLabel",
        headerText: "Point de réception",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "estimatedDateReceipt",
        headerText: "Livraison estimée",
        width: 95,
        maxWidth: 95,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "receiptFormTags",
        headerText: "Tags",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
        showInColumnChooser: true,
        visible: true,
        template: "tagsTemplate",
        clipMode: "EllipsisWithTooltip",
        unavailableExport: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "isLoadingReceiptFormProvidersListByCompany",
      "institutionSelected",
      "workspaceSelected",
      "institutionsList",
      "company",
      "userParametersList",
    ]),
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.$store.getters[this.name + "ProvidersListByCompany"].filter(
          (elem) =>
            elem[this.name + "Tags"].find((tag) =>
              this.tagsSelected.includes(tag.id)
            )
        );
      } else {
        return this.$store.getters[this.name + "ProvidersListByCompany"];
      }
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.tagsSelected.length) {
            return this.$store.getters[
              this.name + "ProvidersListByCompany"
            ].filter((elem) =>
              elem[this.name + "ProvidersListByCompany"].find((tag) =>
                this.tagsSelected.includes(tag.id)
              )
            );
          } else {
            return this.$store.getters[this.name + "ProvidersListByCompany"];
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.tagsSelected.length) {
            return this.$store.getters[
              this.name + "ProvidersListByCompany"
            ].filter(
              (elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                ) &&
                (statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive)
            );
          } else {
            return this.$store.getters[
              this.name + "ProvidersListByCompany"
            ].filter((elem) =>
              statusInfos.query
                ? statusInfos.query.includes(elem.status)
                : elem.status === this.tabActive
            );
          }
        }
      } else {
        return [];
      }
    },
  },
  props: {
    companyId: {
      default: null,
      required: true,
    },
  },
  async created() {
    let filter = this.userParametersList.find(
      (el) => el.key == "companyReceiptFormProviderFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
    await this.getCompanies({});
    await this.getCollaborators({});
    this.getReceiptFormProviderTags({});
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getCollaborators",
      "getReceiptForms",
      "getReceiptFormEmpty",
      "getReceiptFormProviderTags",
    ]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getReceiptForms({
          companyId: this.companyId,
          dateFrom: dateRange.startDate,
          dateTo: dateRange.endDate,
          route: "company",
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création du bon de réception en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      this.getReceiptFormEmpty({
        customerId: this.company.companyType == 8 ? null : this.companyId,
        providerId: this.company.companyType == 8 ? this.companyId : null,
      })
        .then((receiptFormProvider) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          this.$router.push({
            name: "new-receipt-form-provider",
            params: {
              id: receiptFormProvider.id,
              title: "Nouveau bon de réception",
              tips: "Nouveau bon de réception",
              routeOrigine: "receipt-form-providers",
            },
          });
        })
        .catch((err) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          sum += obj["valueHt"];
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          )
          .forEach((obj) => {
            sum += obj["valueHt"];
          });
      }
      return this.formatCurrency(sum);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarReceiptFormProviderContent"].getDocumentDetails(
        event.rowData.id
      );
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  #nav-sticky-horizontal {
    display: none;
  }
}
</style>
