<template lang="pug">
    div
      .border-left.border-right.border-top.rounded-top.content-total
        strong.d-flex.total-row Total net HT
          .ml-auto.price-total
            span.totalWithoutTax {{ formatCurrency(creditProvider.totalHT) }}
        div(v-for="(tva, index) in creditProvider.referentielTvas" :key="index")
          .d-flex.total-row.total-with-tax {{ referencielTvaTranslate(tva.referentielTvaId) }}
            .ml-auto.price-total
              span.totalWithTax {{ formatCurrency(tva.valueTva) }}
        div(v-if="creditProvider.referentielTvas.length > 1")
          strong.d-flex.total-row.total-with-tax Total TVA
            .ml-auto.price-total
              span.totalWithTax {{ formatCurrency((creditProvider.totalTTC - creditProvider.totalHT)) }}
      .border-left.border-right
      strong.d-flex.big.bg-primary.text-white.totalCredit(:class="calcPriceTotalRemainingToPay()!==creditProvider.totalTTC ? '' : 'rounded-bottom'") TOTAL TTC
        .ml-auto.price-total
          span.totalNetToPay.netToPay {{ formatCurrency(creditProvider.totalTTC) }}
      .border-left.border-right.border-bottom.content-total.rounded-bottom(v-if="calcPriceTotalRemainingToPay()!==creditProvider.totalTTC")
        div
          div(v-for='(itemPayment, indexPayment) in creditProvider.paymentProviders' :key="'payment-'+indexPayment")
            .d-flex.total-row Règlement du {{ formatDate(itemPayment.date) }} par {{ itemPayment.paymentMethod }}
              .ml-auto.price-total
                span.totalWithoutTax -{{ formatCurrency(itemPayment.amount) }}
          div
            div(v-for='(itemInvoiceProvider, indexInvoiceProvider) in creditProvider.invoiceProviders' :key="'invoiceProvider-'+indexInvoiceProvider")
              .d-flex.total-row Facture fournisseur {{ itemInvoiceProvider.documentReference }}
                .ml-auto.price-total
                  span.totalWithoutTax -{{ formatCurrency(itemInvoiceProvider.value) }}
          strong.d-flex.total-row {{ "Reste à payer" }}
            span.ml-auto.price-total {{ formatCurrency(calcPriceTotalRemainingToPay()) }}
</template>

<script>
 
import { mapGetters, mapActions } from 'vuex'
import { formatCurrency, formatDate } from "@/types/api-orisis/library/FormatOperations.ts"
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  computed: {
    ...mapGetters(['creditProvider']),
  },
  methods: {
    formatCurrency,
    formatDate,
    referencielTvaTranslate,
    calcPriceTotalRemainingToPay() {
      let paymentProviders = this.creditProvider.paymentProviders
        ? this.creditProvider.paymentProviders.reduce((total, el) => {
            return (total += Math.round(el.amount * 100) / 100);
          }, 0)
        : 0;
      let invoiceProviders = this.creditProvider.invoiceProviders
        ? this.creditProvider.invoiceProviders.reduce((total, el) => {
            return (total += Math.round(el.value * 100) / 100);
          }, 0)
        : 0;
      let priceTotalRemainingToPay = this.creditProvider.remainingToPayTtc =
        Math.round(
          Number(
            this.creditProvider.totalTTC -
            paymentProviders - invoiceProviders)
         * 100) / 100;
      return priceTotalRemainingToPay;
    },
  }
}
</script>

<style>
.totalCredit {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

.total-divider {
  border-top: 1px solid #d9d9d9;
  margin: 7px 0 7px 0;
}

.content-total {
  padding: 7px;
}

.error-margin {
  padding: 10px;
  background-color: #f19e9e;
  display: flex;
  align-items: center;
  border-radius: 0.357rem;
}

.price-total {
  white-space: nowrap;
}

.rest-button {
  margin-left: 10px;
  cursor: pointer;
  color: #0c3571;
}
</style>