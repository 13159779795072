<template lang="pug">
div.px-1.w-100(id="formEditCompanyGeneral")
    .d-flex.pt-1.w-100.inputs-wrapper
        .d-flex.flex-column.mr-1
            b-form-group(label-for="companyIsParticular" label="Type de client")
                b-form-checkbox.custom-control-primary.mt-50(id="companyIsParticular" v-model='companyForm.isParticular')
                    | Particulier
        .d-flex.flex-column.w-25.mr-1.w-100-mobile
            b-form-group(label='Code' label-for='companyCode')
                ejs-textbox(id="companyCode" v-model='companyForm.code')
        .d-flex.flex-column.w-100.mr-1(v-if="!isParticular && loaderIsParticular")
          b-form-group(label='Nom de la société' label-for='companyName')
            vue-typeahead-bootstrap#companyName(type='text' :maxMatches="100" :minMatchingChars="2" :showAllResults='true' name="companyName" v-model='companyForm.name' :data='autocompleteCompanyNameData' :serializer='item => item.nom_raison_sociale ? item.nom_raison_sociale : item.nom_complet' placeholder='Saisissez ou Rechercher une entreprise par nom ou par numéro de siret' @input='autocompleteCompanyNameSource()' @hit='autocompleteCompanyNameSelected' autocomplete='nope' aria-autocomplete='nope')
              template(slot='suggestion' slot-scope='{ data, htmlText }')
                .item
                  span {{ (data.nom_raison_sociale ? data.nom_raison_sociale : data.nom_complet) }}
                  small {{ (data.siege.numero_voie ? data.siege.numero_voie +' ' : '')+(data.siege.type_voie? data.siege.type_voie +' ' : '')+(data.siege.libelle_voie? data.siege.libelle_voie +' ' : '')+', '+data.siege.code_postal+ ' '+data.siege.libelle_commune}}
        .d-flex.flex-column.mr-1.w-25(v-if="isParticular && loaderIsParticular")
          b-form-group(label-for="companyCivility"  label="Civilité *")
            ejs-dropdownlist(id='companyCivility' placeholder='Civilité' popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource='optionCivility' :fields="{ text: 'label', value: 'value' }" v-model='companyForm.civility')
        .d-flex.flex-column.mr-1.w-100(v-if="isParticular && loaderIsParticular")
          b-form-group(label-for="companyFirstName"  label="Prénom")
            ejs-textbox(id="companyFirstName" placeholder="Prénom" v-model="companyForm.firstName" name="companyFirstName")
        .d-flex.flex-column.w-100.mr-1(v-if="isParticular && loaderIsParticular")
          b-form-group(label-for="companyLastName"  label="Nom *")
            ejs-textbox(id="companyLastName" placeholder="Nom" v-model="companyForm.lastName" name="companyLastName" required="")
        .d-flex.flex-column.w-100-mobile
          b-form-group(label-for="companyColor" label="Couleur")
              ejs-colorpicker(id="companyColor" v-model="companyForm.color" mode="Palette")
    .d-flex.flex-column.w-100
      b-form-group(label='Description' label-for='companyDescription')
        ejs-textbox(id="companyDescription" :multiline="true" v-model='companyForm.description' placeholder="Description courte")
    vs-divider.mb-0.mt-2(color='#cccccc')
    div(v-for='(itemAddress, indexAddress) in companyForm.addresses' :key='indexAddress' style="display:contents")
        .d-flex.flex-column.w-100.pt-1
            b-form-group(:label-for="'labelAddress_' + indexAddress")
                template(slot='label')
                    .d-flex.w-100.justify-content-between.align-items-center
                        label(:for="'labelAddress_' + indexAddress" :class="companyForm.addresses.length > 1 ? 'font-weight-bolder' : ''")
                          h5 {{companyForm.addresses.length > 1 ? 'Adresse ' + (indexAddress + 1) : 'Adresse' }}
                        .d-flex.align-items-center(v-if='companyForm.addresses.length > 1')
                          .d-flex.mr-1
                            .d-flex.e-btn.e-primary.justify-space-center.align-items-center(v-if='itemAddress.isDefault' style="cursor: default!important")
                              ejs-tooltip.chip(content="Cette adresse est l'adresse principale")
                                span.link_icon.material-icons-outlined.m-0(style='font-size:16px') grade
                            .d-flex.e-btn.e-outline.e-primary.justify-space-center.align-items-center.cursor-pointer(v-else-if="!itemAddress.isDefault" @click='addFavoriteAddress(indexAddress)')
                              ejs-tooltip.chip(content="Définir comme adresse principale")
                                span.link_icon.material-icons-outlined.m-0.cursor-pointer(style='font-size:16px') grade
                          .d-flex.mr-1
                            .d-flex.e-btn.e-primary.justify-space-center.align-items-center(v-if='itemAddress.isFacturable' @click='toggleFacturableAddress(indexAddress)')
                              ejs-tooltip.chip(content="Cette adresse est l'adresse de facturation")
                                span.link_icon.material-icons-outlined.m-0(style='font-size:16px') description
                            .d-flex.e-btn.e-outline.e-primary.justify-space-center.align-items-center.cursor-pointer(v-else-if="!itemAddress.isFacturable" @click='toggleFacturableAddress(indexAddress)')
                              ejs-tooltip.chip(content="Définir comme adresse de facturation")
                                span.link_icon.material-icons-outlined.m-0.cursor-pointer(style='font-size:16px') description
                          span.link_icon.material-icons-outlined.m-0.cursor-pointer(@click='deleteAddress(itemAddress, indexAddress)' style='font-size:18px') clear
                b-form-input.mb-1(:id="'labelAddress_' + indexAddress" v-model='itemAddress.label' placeholder='Libellé (optionnel)')
                vue-typeahead-bootstrap.not-autocomplete(type='text' :id="'address_' + indexAddress" v-model='itemAddress.address' :ieCloseFix='false' :data='autocompleteAddressData' :serializer='item => item.properties.label' :showAllResults='true' placeholder='Rechercher une adresse ou saisissez : Rue et n° de rue' @input='autocompleteAddressSource(indexAddress)' @hit='autocompleteAddressSelected' autocomplete='nope' aria-autocomplete='nope')
                    template(slot='suggestion' slot-scope='{ data, htmlText }')
                        .item
                            small {{ data.properties.name }}
                            span {{ data.properties.postcode+ ' ' + data.properties.city }}
            .d-flex.flex-column.w-100.pt-1
                b-form-input(:id="'addressComplement_' + indexAddress" v-model='itemAddress.addressComplement' placeholder='Bâtiment, Appartement..')
        .d-flex.w-100.pt-1
                b-form-input.mr-1(:id="'zipcode_' + indexAddress" v-model='itemAddress.zipCode' placeholder='Code postal')
                b-form-input(:id="'city_' + indexAddress" v-model='itemAddress.city' placeholder='Ville')
        .d-flex.flex-column.w-100.pt-1
                b-form-input(:id="'country_' + indexAddress" v-model='itemAddress.country' placeholder='Pays')
        .d-flex.flex-column.w-100.pt-1
                b-form-input(:id="'siret_' + indexAddress" v-model='itemAddress.siret' placeholder='Numéro de Siret')
        .d-flex.flex-column.w-100.pt-1
            label(for="address'") Coordonnées GPS
            .d-flex.align-items-center.w-100
                b-form-input.w-100.mr-1(:id="'latitude_' + indexAddress" v-model='itemAddress.latitude' placeholder='Latitude')
                b-form-input.w-100(:id="'longitude_' + indexAddress" v-model='itemAddress.longitude' placeholder='Longitude')
    .d-flex.w-100.pt-1
      small.cursor-pointer.text-primary(@click='addAddress') + Ajouter une adresse

    vs-divider.mb-0.mt-2(color='#cccccc')
    .d-flex.w-100.pt-1.inputs-wrapper
        .d-flex.flex-column.w-100.pt-1.mr-1
            b-form-group(label-for="companyEmail"  label="Adresse email principale")
                ejs-textbox(id="companyEmail" placeholder="Adresse email principale" v-model="companyForm.email" name="Email")
        .d-flex.flex-column.w-100.pt-1.mr-1
            b-form-group(label-for="companyPhoneNumber"  label="Téléphone principale")
                ejs-textbox(id="companyPhoneNumber" placeholder="Téléphone principale" v-model="companyForm.phoneNumber" name="PhoneNumber")
        .d-flex.flex-column.w-100.pt-1
            b-form-group(label-for="companySecondaryPhoneNumber"  label="Téléphone secondaire")
                ejs-textbox(id="companySecondaryPhoneNumber" placeholder="Téléphone secondaire" v-model="companyForm.secondaryPhoneNumber" name="SecondaryPhoneNumber")
    .d-flex.w-100.pt-1
        b-form-group.w-100(label-for="website"  label="Site internet")
            ejs-textbox(id='website' name="Site internet" placeholder='Ajouter votre site internet' v-model='companyForm.website')
    vs-divider.mb-0.mt-2(color='#cccccc')
    .d-flex.w-100.pt-1.inputs-wrapper
        .d-flex.flex-column.w-100.pt-1.mr-1
            b-form-group(label-for="companySiren"  label="Siren")
                ejs-textbox(id="companySiren" placeholder="Numéro de Siren" v-model="companyForm.siren" name="Siren")
        .d-flex.flex-column.w-100.pt-1.mr-1
            b-form-group(label-for="companyTva"  label="Numéro de TVA")
                ejs-textbox(id="companyTva" placeholder="Numéro de TVA" v-model="companyForm.tvaNumber" name="Numéro de TVA")
        .d-flex.flex-column.w-100.pt-1
            b-form-group(label-for="companyAccount"  label="Compte auxiliaire")
                ejs-textbox(id="companyAccount" placeholder="Compte auxiliaire" v-model="companyForm.companyAccount" name="Compte auxiliaire")
    vs-divider.mb-0.mt-2(color='#cccccc')                 
    .d-flex.flex-column.w-100.py-1
            b-form-group.w-100(label-for="companyCollaboratorId"  label="Suivi par *")
                ejs-dropdownlist(id='companyCollaboratorId' name="Collaborateur" placeholder='Selectionnez un collaborateur' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="collaboratorsList.map(elem => { label = elem.firstName + ' ' + elem.lastName; return { ...elem, label: label }})" :fields="{ text: 'label', value: 'id' }" v-model='companyForm.collaboratorId' required)
                    template(v-slot:footerTemplate)
                        div.p-50
                            ejs-button(v-on:click.native="newCollaborator()" :content="'+ Nouveau collaborateur'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
   
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { debounce } from "lodash";
import { ECivility } from "@/types/api-orisis/enums/enums";

import { FormValidator } from "@syncfusion/ej2-vue-inputs";
import { MaskedTextBoxComponent } from "@syncfusion/ej2-vue-inputs";

export default {
  components: {
    "ejs-maskedtextbox": MaskedTextBoxComponent,
  },
  props: {
    companyId: {
      default: null,
      required: true,
    },
    companyForm: {},
  },
  data() {
    return {
      loaderIsParticular: true,
      autocompleteCompanyName: "",
      autocompleteCompanyNameData: [],
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      hoverDefaultAddressIndex: -1,
      hoverFacturableAddressIndex: -1,
      optionCivility: [
        {
          label: "M. et Mme",
          value: ECivility.MonsieurMadame,
        },
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },

        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      fieldsCategories: {
        dataSource: this.companyCategoriesTreeList,
        value: "id",
        text: "label",
        child: "children",
      },

      formObj: "",

      options: {
        customPlacement: function (inputElement, errorElement) {
          inputElement = inputElement
            .closest(".form-group")
            .querySelector(".error");
          inputElement.parentElement.appendChild(errorElement);
        },
        rules: {
          companyLastName: {
            required: [true, "Indiquez le nom"],
          },
          companyName: {
            required: [true, "Indiquez le nom de la société"],
          },
          Collaborateurs: {
            required: [true, "Sélectionnez au moins un collaborateur"],
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "company",
      "isLoadingCompany",
      "companiesList",
      "isLoadingCompanyCategoriesTreeList",
      "companyCategoriesTreeList",
      "isLoadingUser",
      "isLoadingCollaboratorsList",
      "isLoadingCompany",
      "collaboratorsList",
      "isUpdatingCompany",
    ]),
    isParticular: {
      get() {
        return this.companyForm.isParticular;
      },
    },
  },
  watch: {
    "companyForm.isParticular": function (newVal, oldVal) {
      this.loaderIsParticular = false;
      setTimeout(() => {
        this.loaderIsParticular = true;
      }, 50);
    },
    "companyForm.ownAddressCompany": function (newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.deteleAutocomplete();
        });
      }
    },
  },
  async created() {
    this.getCollaborators({});
  },
  mounted() {
    this.deteleAutocomplete();
    // this.formObj = new FormValidator("#formEditCompanyGeneral", this.options);
  },
  methods: {
    ...mapActions(["archiveCompanies", "getCollaborators"]),
    // Autocompte Company from data.gouv
    autocompleteCompanyNameSource: debounce(function () {
      if (this.companyForm.name.trim().length > 2) {
        fetch(
          `https://recherche-entreprises.api.gouv.fr/search?q=${this.companyForm.name}&page=1&per_page=25`
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.autocompleteCompanyNameData = data.results;
          });
      }
    }, 500),
    autocompleteCompanyNameSelected(item) {
      this.companyForm.siret = item.siege.siret;
      this.companyForm.siren = item.siren;
      this.companyForm.addresses = [];
      this.companyForm.addresses.push({
        address:
          (item.siege.numero_voie ? item.siege.numero_voie + " " : "") +
          (item.siege.type_voie ? item.siege.type_voie + " " : "") +
          item.siege.libelle_voie,
        addressComplement: item.siege.complement_adresse,
        zipCode: item.siege.code_postal,
        city: item.siege.libelle_commune,
        country: "France",
        label: null,
        isDefault: true,
        latitude: item.siege.latitude,
        longitude: item.siege.longitude,
        id: 0,
      });
      this.companyForm.name = item.nom_raison_sociale
        ? item.nom_raison_sociale
        : item.nom_complet;
    },

    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function (indexSource) {
      let _this = this;
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${this.companyForm.addresses[indexSource].address}&autocomplete=0&limit=50`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.companyForm.addresses[this.autocompleteAddressIndex].address =
        item.properties.name;
      this.companyForm.addresses[this.autocompleteAddressIndex].city =
        item.properties.city;
      this.companyForm.addresses[this.autocompleteAddressIndex].zipCode =
        item.properties.postcode;
      this.companyForm.addresses[this.autocompleteAddressIndex].latitude =
        item.geometry.coordinates[1];
      this.companyForm.addresses[this.autocompleteAddressIndex].longitude =
        item.geometry.coordinates[0];
      this.companyForm.addresses[this.autocompleteAddressIndex].country =
        "France";
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
      elements = document.querySelectorAll(".not-autocomplete");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    addAddress() {
      this.companyForm.addresses.push({
        address: null,
        addressComplement: null,
        zipCode: null,
        city: null,
        country: null,
        label: null,
        isDefault: this.companyForm.addresses.length > 0 ? false : true,
        isFacturable: false,
        id: 0,
      });
      if (this.companyForm.addresses.length === 2) {
        this.addFavoriteAddress(0);
      }
      this.$nextTick(() => {
        this.deteleAutocomplete();
      });
    },
    updateAddresses(index, isFacturableUpdate = false) {
      const addressToUpdate = this.companyForm.addresses[index];

      for (let i = 0; i < this.companyForm.addresses.length; i++) {
        const address = this.companyForm.addresses[i];
        if (i === index) {
          if (!isFacturableUpdate) {
            address.isDefault = true;
          }
          if (isFacturableUpdate) {
            address.isFacturable = !address.isFacturable;
          }
        } else {
          if (!isFacturableUpdate) {
            address.isDefault = false;
          }
          if (isFacturableUpdate) {
            address.isFacturable = false;
          }
        }
        this.updateLabel(address);
      }

      if (isFacturableUpdate && !addressToUpdate.isFacturable) {
        if (
          !addressToUpdate.label ||
          addressToUpdate.label === "Adresse de facturation" ||
          addressToUpdate.label ===
            "Adresse de facturation - Adresse favorite" ||
          addressToUpdate.label === "Adresse favorite"
        ) {
          this.updateLabel(addressToUpdate);
        }
      }
    },

    updateLabel(address) {
      const defaultLabels = [
        "Adresse de facturation",
        "Adresse de facturation - Adresse favorite",
        "Adresse favorite",
      ];
      const isDefaultLabel = defaultLabels.includes(address.label);

      if (!address.label || isDefaultLabel) {
        if (address.isFacturable) {
          address.label = address.isDefault
            ? "Adresse de facturation - Adresse favorite"
            : "Adresse de facturation";
        } else {
          address.label = address.isDefault ? "Adresse favorite" : "";
        }
      }
    },

    // Utilisation des fonctions
    addFavoriteAddress(index) {
      this.updateAddresses(index);
    },

    toggleFacturableAddress(index) {
      this.updateAddresses(index, true);
    },

    deleteAddress(item, index) {
      this.$bvModal
        .msgBoxConfirm(
          "En poursuivant, vous supprimerez l'adresse et ses informations.",
          {
            title: `Êtes-vous sûr de vouloir supprimer l'adresse ${
              index + 1
            } ?`,
            size: "sm",
            okVariant: "danger",
            okTitle: "Supprimer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.companyForm.addresses.splice(index, 1);
          }
        });
    },
    archiveCompanyLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveCompanes({ companyIds: [id] });
            this.$tabs.close();
          }
        });
    },
    newCompany() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
    newCollaborator() {
      this.$router.push({
        name: "new-collaborator",
        params: { routeOrigine: "new-company/" },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .inputs-wrapper {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
